/* React */
import { useCallback, useEffect, useState, useContext, useReducer } from "react";

/* APIs */
import ResourceApi from '../../services/resource';

/* Contexts */
import { ExaminationContext } from "../Examination";
import { SocketContext } from "../Socket";
import { MeasurementsContext } from "../Measurements";

import { placeholderIdFromProps } from '../../components/XMLDocument/utils';

const mandatoryPlaceholders = [
  "examination.finding",
  "examination.indication",
  "ga.assigned.value",
  "examination.method",
  "examination.signed"
];

const reloadPlaceholders = (channel, ids) => {
  channel.push("require_data", { ids });
}

const placeholdersReducer = ({ placeholders, requiredPlaceholders }, { event, data: { data, reportV2Channel } }) => {
  switch (event) {
    case "require":
      const ids = data.ids.filter(id => !placeholders.hasOwnProperty(id) && !requiredPlaceholders.includes(id));
      reportV2Channel.push("require_data", { ids });
      return { placeholders, requiredPlaceholders: [...requiredPlaceholders, ...ids] };
    case "reload":
      reloadPlaceholders(reportV2Channel, [...Object.keys(placeholders), ...requiredPlaceholders]);
      return { placeholders, requiredPlaceholders };
    case "update":
      return { placeholders: { ...placeholders, ...data }, requiredPlaceholders: requiredPlaceholders.filter(id => !data.hasOwnProperty(id)) };
    default:
      throw new Error(`Unknown event: ${event}`);
  }
};

const XMLTemplateContextProvider = ({ XMLTemplateContext, children }) => {

  const [socketLoaded, setSocketLoaded] = useState(false);
  const [reportV2Channel, setReportV2Channel] = useState(null);
  const [reportDataOptions, setReportDataOptions] = useState({});
  const [requiredPlaceholdersPromises, setRequiredPlaceholdersPromises] = useState([]);
  const examinationContext = useContext(ExaminationContext);
  const measurementsContext = useContext(MeasurementsContext);

  const { socket } = useContext(SocketContext);
  const [{ placeholders }, doDispatchPlaceholders] = useReducer(
    placeholdersReducer,
    { placeholders: {}, requiredPlaceholders: mandatoryPlaceholders }
  );
  const dispatchPlaceholders = useCallback((event, data) => {
    doDispatchPlaceholders({ event, data: { data, reportV2Channel } })
  }, [reportV2Channel, doDispatchPlaceholders]);

  const loadStaticReportOptions = async () => {
    if (!examinationContext?.examination?.id) return;
    const response = await ResourceApi.getReportOptions(examinationContext.examination.id);
    setReportDataOptions({...response.data, labels: measurementsContext?.labels});
  };

  useEffect(() => {
    setReportDataOptions((reportDataOptions) => ({...reportDataOptions, labels: measurementsContext.labels}));
  }, [measurementsContext.labels]);

  /*
   * Legacy fields
   */

  const [componentChecklistAssoc, setComponentChecklistAssoc] = useState({});
  const [dynamicDropdowns, setDynamicDropdowns] = useState({});
  const [automationTemplateFieldsVisible, setAutomationTemplateFieldsVisible] = useState(false);
  const [highlightedFields, setHighlightedFields] = useState([]); // example: [{id: "examination.method", icon: 'flash', iconClass: 'selected', source: 'ambientListening' }]
  const [autogeneratedChecklistComments, setAutogeneratedChecklistComments] = useState([]);
  const updateAutogeneratedChecklistComments = (fetus, collection, comments) => {
    setAutogeneratedChecklistComments(autogeneratedChecklistComments => {
      if (!autogeneratedChecklistComments[fetus]) autogeneratedChecklistComments[fetus] = {};
      let pendingUpdates = comments.length !== autogeneratedChecklistComments[fetus][collection]?.length;
      if (!pendingUpdates) {
        const currentAutomation = Object.fromEntries(autogeneratedChecklistComments[fetus][collection]?.map((c) => [c.data, c.content]) || []);
        for (const comment of comments) {
          if (comment?.data && currentAutomation[comment.data] !== comment?.content) {
            pendingUpdates = true;
            break;
          }
        }
      }
      if (pendingUpdates) {
        autogeneratedChecklistComments[fetus][collection] = comments;
        // simple copy to trigger re-render and useEffect-s
        return { ...autogeneratedChecklistComments };
      }
      // no updates - will not trigger re-render and useEffect-s
      return autogeneratedChecklistComments;
    })
  };

  useEffect(() => {
    loadStaticReportOptions();
  }, [
    examinationContext?.examination?.id,
    examinationContext?.examination?.site_id,
    examinationContext?.examination?.preset_id
  ]);

  useEffect(() => {
    if (requiredPlaceholdersPromises.length === 0) return;
    setRequiredPlaceholdersPromises((requiredPlaceholdersPromises) => {
      return requiredPlaceholdersPromises.filter(({ ids, resolve }) => {
        if (ids.every(id => placeholders[id])) {
          resolve(Object.fromEntries(ids.map(id => [id, placeholders[id]])));
          return false;
        }
        return true;
      });
    });
  }, [placeholders, requiredPlaceholdersPromises]);

  useEffect(() => {
    const examinationId = examinationContext?.examination?.id;
    if (reportV2Channel) reportV2Channel.leave();
    setSocketLoaded(false);

    if (socket && examinationId) {
      const channelTopic = `report_v2:${examinationId}`;

      const channel = socket.channel(channelTopic);

      channel.on("update", (payload) => {
        // TODO
        console.log("• ReportV2 Channel: received \"update\" message", payload);
        /* For the moment we only send data_attribute */
        switch (payload.resource_type) {
          case "data_attributes":
            setSocketLoaded(true);
            dispatchPlaceholders("update", payload.data);
            break;
        }
      });

      channel.on("error", (payload) => {
        console.error("• ReportV2 Channel: received \"error\" message", payload);
      });

      channel.on("debug", (payload) => {
        console.debug("• ReportV2 Channel: received \"debug\" message", payload);
      });

      channel.on("warning", (payload) => {
        // TODO put this messages on the debug pannel
        console.warn("• ReportV2 Channel: received \"warning\" message", payload);
      });


      channel
        .join()
        .receive("ok", () => {
          /* Example of usage:
           * reportV2Channel.push("require_data", {ids: ["patient.firstname", "patient.lastname", "patient.dob", "patient.age", "patient.sex"]})
           * reportV2Channel.push("update_data", {data: [{slug: "patient.firstname", examination_fetus_id: null, source: "user", selected: true, options: {comment: "", visibility: true}, value: {value: "John"}}]})
           */
          window.reportV2Channel = channel;
          setReportV2Channel(channel);
          doDispatchPlaceholders({ event: "reload", data: { reportV2Channel: channel } });
        })
        .receive("error", () => {
          console.error(
            `Join resource channel with topic ${channelTopic} failed`
          );
        });
    }
  }, [socket, examinationContext?.examination?.id]);

  /*
   * @param {Array} ids - Array of placeholders to require
   * @returns {Promise} - Promise that resolves when all placeholders are loaded. Resolve value is the loaded placeholders
   *
   * @example
   * // returns { "patient.firstname": {...}, "patient.lastname": {...}, "patient.dob": {...}, "patient.age": {...}, "patient.sex": {...}}
   * const placeholders = await requirePlaceholders(["patient.firstname", "patient.lastname", "patient.dob", "patient.age", "patient.sex"]);
   */
  const requirePlaceholders = useCallback((ids) => {
    return new Promise((resolve) => {
      dispatchPlaceholders("require", { ids });
      setRequiredPlaceholdersPromises((requiredPlaceholdersPromises) => {
        return [...requiredPlaceholdersPromises, { ids, resolve }];
      });
    });
  }, [reportV2Channel]);


  window.placeholders = placeholders;
  const apiVersion = "2.0";
  window.apiVersion = "2.0";
  window.requirePlaceholders = requirePlaceholders;

  const BIContext = {
    examination_status: examinationContext.examination.preset_id,
    examination_preset_id: examinationContext.examination.preset_id,
    examination_id: examinationContext.examination.id,
    report_id: reportDataOptions?.report_id,
    report_version: examinationContext.examination.report_version
  }

  return (
    <XMLTemplateContext.Provider value={{
      /* Legacy fields */
      updateAutogeneratedChecklistComments,
      /* New fields */
      loaded: (socketLoaded && !!reportDataOptions),
      placeholders,
      requirePlaceholders,
      reportDataOptions,
      BIContext,
      apiVersion,
    }}>
      {children}
    </XMLTemplateContext.Provider>
  );
};

export default XMLTemplateContextProvider;
