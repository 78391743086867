/* React */
import { useCallback, useEffect, useState, useReducer, useContext } from "react";
import { withTranslation } from 'react-i18next';

/* Contexts */
import { AppContext } from "../App";
import { ExaminationContext } from "../Examination";
import { NotificationContext } from "../Notification";
import { MeasurementsContext } from "../Measurements";
import useAuth from "../Auth";
import { SocketContext } from "../Socket";


/* Services */
import ResourceApi from '../../services/resource';
import evalTemplate from "../../services/automation-template";
import { computeBMI, formatName, getNiceGestionalAgeFromDays, createFullName } from '../../services/examination';

/* Utils & Components */
import { deepMerge, getPatientAge, isNullOrUndefined, timeFormatter, convertTimeZone, replaceAllKeys } from '../../utils';

import EditBiometry from '../../pages/ExaminationReport/editors/EditBiometry';
import Icon from "../../atoms/Icon/Icon";

const templateIdToMedicalHistoryId = (id) => {
  if (id.startsWith('custom.'))
    return templateIdToMedicalHistoryId(id.slice('custom.'.length));
  if (id.startsWith('medical-history.'))
    return id.slice('medical-history.'.length);
  if (id.startsWith('patient.'))
    return id;
  if (id.startsWith('episode.'))
    return id;
  /* Not a medical history item */
  return false;
}

const medicalHistoryIdToTemplateIds = (id) => {
  if (id.startsWith('patient.'))
    return [id, 'custom.' + id]
  return ['medical-history.' + id, 'custom.medical-history.' + id]
}

const medicalHistoryItem = (id, medicalHistoryItems) => {
  const idMH = templateIdToMedicalHistoryId(id)
  return medicalHistoryItems[idMH] || { text_id: idMH };
}

const updateChecklistItemStatus = (items, item) => {
  const index = items.findIndex(({ id, examination_fetus_id }) => item.id == id && examination_fetus_id == item.examination_fetus_id)

  if (index !== -1) {
    items[index] = item
    return items
  }
  return items.concat(item)
}

const reportDataReducer = ({ loadeds, state, incr }, { event, data }) => {
  switch (event) {
    case "reset":
      return { incr: incr + 1, loadeds: { dynamic: false, measurements: false }, state: {} }
    case "user_edits":
      return {
        incr: incr + 1, loadeds, state: {
          ...state,
          report: deepMerge(state.report, { report: { user_edits: data } })
        }
      }

    case "checklist_items_with_statuses":
      return {
        incr: incr + 1, loadeds, state: {
          ...state,
          checklist_items_with_statuses: data
        }
      }

    case "frozen":
      return { incr: incr + 1, loadeds, state: { ...state, ...data }, loadeds: { dynamic: true, measurements: true } };
    case "socket":
    case "backup":
    case "internal":
      return { incr: incr + 1, loadeds, state: { ...state, ...data } };
    case "dynamic":
      return { incr: incr + 1, loadeds: { ...loadeds, dynamic: true }, state: { ...state, ...data } };
    case "static":
      return { incr: incr + 1, loadeds: { ...loadeds, static: true }, state: { ...state, ...data } };
    case "measurements":
      return { incr: incr + 1, loadeds: { ...loadeds, measurements: true }, state: { ...state, ...data } };
  }
}

const XMLTemplateContextProvider = withTranslation()(({ t: __, children, XMLTemplateContext }) => {
  const { isFeatureFlagEnabled } = useAuth();
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const notificationContext = useContext(NotificationContext);
  const measurementsContext = useContext(MeasurementsContext);
  const { socket } = useContext(SocketContext);
  const [reportDataOptions, doSetReportDataOptions] = useState({});
  const [newReportData, dispatchNewReportData] = useReducer(
    reportDataReducer,
    {
      loadeds: { dynamic: false, measurements: false },
      state: {},
      incr: 0
    }
  );
  const reportData = newReportData.state
  const reportDataIncr = newReportData.incr
  const loadingStatus = newReportData.loadeds
  const setReportDataDynamic = (data) => dispatchNewReportData({ event: "dynamic", data })
  const setReportDataOptions = (data) => {
    doSetReportDataOptions(data);
    return dispatchNewReportData({ event: "static", data })
  }
  const setReportDataMeasurements = (data) => dispatchNewReportData({ event: "measurements", data })
  const setReportData = (data) => dispatchNewReportData({ event: "internal", data })

  const [reportChannel, setReportChannel] = useState(null);
  const [placeholders, setPlaceholders] = useState({});
  const [customPlaceholders, setCustomPlaceholders] = useState({});
  const [editingFieldId, setEditingFieldId] = useState({});
  const [examinationSite, setExaminationSite] = useState(null);
  const [mandatoryChecklistSlugs, setMandatoryChecklistSlugs] = useState([]);
  const [componentChecklistAssoc, setComponentChecklistAssoc] = useState({});
  const [dynamicDropdowns, setDynamicDropdowns] = useState({});
  const [automationTemplateFieldsVisible, setAutomationTemplateFieldsVisible] = useState(false);
  const [highlightedFields, setHighlightedFields] = useState([]); // example: [{id: "examination.method", icon: 'flash', iconClass: 'selected', source: 'ambientListening' }]
  const [autogeneratedChecklistComments, setAutogeneratedChecklistComments] = useState([]);
  const updateAutogeneratedChecklistComments = (fetus, collection, comments) => {
    if (!autogeneratedChecklistComments[fetus]) autogeneratedChecklistComments[fetus] = {};
    let pendingUpdates = comments.length !== autogeneratedChecklistComments[fetus][collection]?.length;
    if (!pendingUpdates) {
      const currentAutomation = Object.fromEntries(autogeneratedChecklistComments[fetus][collection]?.map((c) => [c.data, c.content]) || []);
      for (const comment of comments) {
        if (comment?.data && currentAutomation[comment.data] !== comment?.content) {
          pendingUpdates = true;
          break;
        }
      }
    }
    if (pendingUpdates) {
      setAutogeneratedChecklistComments(c => {
        c[fetus][collection] = comments;
        return c;
      });
    }
  }

  const examId = examinationContext.examination?.id;
  const examinationFrozen = examinationContext.examination?.frozen;
  const trimester = examinationContext.examination?.trimester;

  const fetuses = [{ id: null, dicom_id: 0 }].concat(reportData?.examination_data?.fetuses || []);
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();

  const BIContext = {
    examination_status: reportData?.examination_data?.status,
    examination_preset_id: reportData?.examination_data?.preset_id,
    examination_id: reportData?.examination_data?.id,
    report_id: reportData?.report?.id,
  }

  const getDataAttributeSlugs = useCallback((dataAttribute, custom) => {
    return dataAttribute.includes("|")
      ? dataAttribute.split("|").map(v => (custom ? "custom." : "") + v.replace(/\&/g, "").trim())
      : dataAttribute.split("&").map(v => (custom ? "custom." : "") + v.replace(/\|/g, "").trim());
  }, []);

  const getDataAttributeOperator = useCallback((dataAttribute) => {
    return dataAttribute.includes("|") ? "OR" : "AND";
  }, []);

  useEffect(() => {
    const examinationId = examinationContext?.examination?.id;
    if (reportChannel) reportChannel.leave();

    if (socket && examinationId) {
      const channelTopic = `report:${examinationId}`;

      const channel = socket.channel(channelTopic);

      channel.on("update", (payload) => {
        switch (payload.resource_type) {
          case "report_data":
            return dispatchNewReportData({ event: "socket", data: payload.data });
          default:
            return;
        }
      });
      channel
        .join()
        .receive("ok", () => {
          setReportChannel(channel);
        })
        .receive("error", () => {
          console.error(
            `Join resource channel with topic ${channelTopic} failed`
          );
        });
    }
  }, [socket, examinationContext?.examination?.id]);

  useEffect(() => {
    // TODO: currently it's always parsing the report template, but we have to extend to other templates in the future (the MH one for example)
    // TODO: we should allow mandatory or not to be defined as a config and move away from the report template 
    const templateBlueprint = examinationContext.debugTemplate || reportData?.report_template?.blueprint;
    const xmlParser = new DOMParser();
    setMandatoryChecklistSlugs(Object.values(xmlParser.parseFromString(templateBlueprint, "application/xml")
      .getElementsByTagName("checklist") || {})
      .reduce((slugs, elm) => [...slugs, ...(elm.getAttribute("items")?.split("|").filter(slug => !slugs.includes(slug)) || [])], [])
    );
  }, [examinationContext.debugTemplate, reportData?.report_template?.blueprint]);

  const addComponentChecklistAssoc = (componentId, itemSlugs) => setComponentChecklistAssoc(assoc => {
    assoc[componentId]?.filter(slug => !itemSlugs.includes(slug));
    assoc[componentId] = [...(assoc[componentId] || []), ...(itemSlugs)];
    return assoc;
  });
  const removeComponentChecklistAssoc = (componentId) => setComponentChecklistAssoc(assoc => {
    delete assoc[componentId];
    return assoc;
  });

  useEffect(() => {
    if (isFeatureFlagEnabled("sonio.report_dropdown_options")) {
      setDynamicDropdowns(dropdowns => deepMerge(dropdowns, reportData.report_dropdowns));
    }
  }, [JSON.stringify(reportData.report_dropdowns)]);

  const checkCondition = useCallback((props, fetus = 0, getPlaceholderWithProps) => {
    let { data: dataAttribute, attribute, custom = false, includes = "", is = "", to = "", than = "", low = "", high = "" } = props;
    if (!than && to) than = to; // to is an alias of than
    if (includes) is = "includes";
    if (!is) is = "not-empty";

    let placeholders = [];
    const operator = getDataAttributeOperator(dataAttribute);
    const dataList = getDataAttributeSlugs(dataAttribute, custom);
    const includesList = getDataAttributeSlugs(includes || "");

    let isTrueAccordingToOperator = operator === "AND";

    for (const placeholderData of dataList) {
      const propsWithData = { ...props, data: placeholderData };
      let placeholder = getPlaceholderWithProps(propsWithData);

      if (!Array.isArray(placeholder)) {
        if (!placeholder.hasOwnProperty("value")) placeholder.value = null;
        if (!placeholder.hasOwnProperty("normal")) placeholder.normal = null;
        if (!placeholder.hasOwnProperty("viewed")) placeholder.viewed = null;
      }

      if (fetus && placeholder[0] && !placeholder[fetus]) fetus = 0; // temporary workaround for patient measurements
      const fetusPlaceholder = !Array.isArray(placeholder)
        ? (!Array.isArray(placeholder.value) ? placeholder : { ...placeholder, value: placeholder.value[fetus] })
        : (fetus ? placeholder[fetus] : placeholder.find(elm => elm));
      placeholder.valueToCheck = (attribute ? (fetusPlaceholder?.[attribute] || false) : fetusPlaceholder?.value);
      if (!!placeholder.value && typeof placeholder.value !== "object") {
        placeholder.valueToCheck = placeholder.valueToCheck ? `${placeholder.valueToCheck}` : false; // to string only if set -> important for matching empty/not-empty statuses
      }

      placeholders.push(placeholder);
    }

    for (const placeholder of placeholders) {
      let isTrue = false;
      const valueToCheck = placeholder.valueToCheck;
      const normal = placeholder.normal;
      const viewed = placeholder.viewed;

      switch (is) {
        case "empty":
        case "":
          isTrue = !valueToCheck;
          break;

        case "not-empty":
        case "not_empty":
          isTrue = !!valueToCheck;
          break;

        case "equal":
        case "=":
          if (valueToCheck !== null && than !== null) {
            isTrue = `${valueToCheck}`.toLowerCase() === `${than}`.toLowerCase();
          }
          break;

        case "not-equal":
        case "not_equal":
        case "!=":
          if (valueToCheck !== null && than !== null) {
            isTrue = `${valueToCheck}`.toLowerCase() !== `${than}`.toLowerCase();
          }
          break;

        case "greater":
        case ">":
          isTrue = (Number(valueToCheck) > Number(than));
          break;

        case "greater_or_equal":
        case "greater-or-equal":
        case "greater or equal":
        case ">=":
          isTrue = (Number(valueToCheck) >= Number(than));
          break;

        case "less":
        case "lower":
        case "<":
          isTrue = (Number(valueToCheck) < Number(than));
          break;

        case "less_or_equal":
        case "lower_or_equal":
        case "less-or-equal":
        case "lower-or-equal":
        case "less or equal":
        case "lower or equal":
        case "<=":
          isTrue = (Number(valueToCheck) <= Number(than));
          break;

        case "between":
        case "><":
          if (low !== null && high !== null) {
            isTrue = (Number(valueToCheck) <= Number(high) && Number(valueToCheck) >= Number(low));
          }
          break;

        case "not-between":
        case "not_between":
        case "<>":
          if (low !== null && high !== null) {
            isTrue = (Number(valueToCheck) > Number(high) || Number(valueToCheck) < Number(low));
          }
          break;

        case "normal":
          isTrue = !!normal;
          break;

        case "not_normal":
        case "not-normal":
          isTrue = (normal !== null && !normal);
          break;

        case "viewed":
          isTrue = !!viewed;
          break;

        case "not_viewed":
        case "not-viewed":
          isTrue = (viewed !== null && !viewed);
          break;

        case "includes":
          // TODO: check includesList by operator, at now everything is always considered as OR
          isTrue = (!!valueToCheck && typeof valueToCheck === 'object') ? includesList.some(v => !!valueToCheck[v]) : valueToCheck?.includes(includes);
          break;
      }

      if (operator === "AND") {
        isTrueAccordingToOperator = (isTrueAccordingToOperator && isTrue) ? true : false;
      } else if (operator === "OR" && isTrue) {
        isTrueAccordingToOperator = true;
      }
    }

    return isTrueAccordingToOperator;
  }, []);

  const updateReport = async (examId, changes, backupReport) => {
    try {
      const response = await ResourceApi.updateReport(examId, changes);
      setReportDataDynamic({ report: response?.data })
      return true;
    }
    catch (error) {
      console.error(error)
      if (backupReport)
        setReportData(backupReport);

      notificationContext.showNotification(<><Icon name="warning" />{" "}{__("report.unableToUpdate")}</>, 5000);
      return false;
    }
  }


  const loadMeasurementsReportData = async () => {
    await measurementsContext.loadMeasurementsData()
  };

  const loadFrozenReportData = async () => {
    if (!examId)
      return
    const response = await ResourceApi.getReportFrozen(examId);
    dispatchNewReportData({ event: "frozen", data: response.data })
    setReportDataOptions(response.data);
  }


  const loadDynamicReportData = async () => {
    if (!examId) return;
    const response = await ResourceApi.getReportDynamic(examId);
    setReportDataDynamic(response.data);
  };

  const loadStaticReportOptions = async () => {
    setDynamicDropdowns({});
    if (!examId) return;
    const response = await ResourceApi.getReportOptions(examId);
    setReportDataOptions(response.data);
  };


  const patientView = {
    /* No controller so not editable */
    'patient.name': {
      view: (patient, id) => ({
        ...getDataById(id),
        value: patient.fullName,
        format: "string"
      })
    },
    'patient.lastname': {
      view: (patient, id) => ({
        ...getDataById(id),
        value: patient.lastName,
        format: 'string'
      }),
      controller: (value, attrs) => ({ ...attrs, lastName: value })
    },
    'patient.firstname': {
      view: (patient, id) => ({
        ...getDataById(id),
        value: patient.firstName,
        format: 'string'
      }),
      controller: (value, attrs) => ({ ...attrs, firstName: value })
    },
    'patient.middlename': {
      view: (patient, id) => ({
        ...getDataById(id),
        value: patient.middleName,
        format: 'string'
      }),
      controller: (value, attrs) => ({ ...attrs, middleName: value })
    },
    'patient.title': {
      view: (patient, id) => ({
        ...getDataById(id),
        value: patient.prefix,
        format: 'string'
      }),
      controller: (value, attrs) => ({ ...attrs, prefix: value })
    },
    'patient.dob': {
      view: (patient, id) => ({
        ...getDataById(id),
        value: patient.dob,
        format: 'date'
      }),
      controller: (value, attrs) => ({ ...attrs, dob: value })
    },
    'patient.age': {
      /* No controller so not editable */
      view: (patient, id, context) => ({
        ...getDataById(id),
        value: getPatientAge(patient.dob, context?.examination_data?.examination_date),
        format: 'string'
      })
    },
    'patient.address': {
      view: (patient, id) => ({
        ...getDataById(id),
        value: patient.address,
        format: 'string'
      }),
      controller: (value, attrs) => ({ ...attrs, address: value })
    },
    'patient.sex': {
      view: (patient, id) => ({
        ...getDataById(id),
        ...medicalHistoryOptions('patient.gender'),
        value: patient.sex,
      }),
      controller: (value, attrs) => ({ ...attrs, sex: value })
    },
    'patient.gender': {
      view: (patient, _id) => ({
        ...getDataById('patient.sex'),
        ...medicalHistoryOptions('patient.gender'),
        value: patient.sex,
      }),
      controller: (value, attrs) => ({ ...attrs, sex: value })
    },
    'patient.ehr_id': {
      /* No controller so not editable */
      view: (patient, id) => ({
        ...getDataById(id),
        value: patient.ehr_id || patient.dicom_patient_id,
        format: 'string'
      })
    },
    'patient.mail': {
      view: (patient, id) => ({
        ...getDataById(id),
        value: patient.email_address,
        format: 'string'
      }),
      controller: (value, attrs) => ({ ...attrs, email_address: value })
    }
  }

  /* Patient Controller */
  const updatePatient = async (attrs) => {
    if (!examinationContext.canEdit)
      return
    /* Here we can use the examinationContext as the examination is not frozen */
    const { lastName, firstName, middleName, prefix } = { ...formatName(examinationContext.patient?.name), ...attrs };

    if (!examinationContext.patient?.id) {
      createPatientAndAssignToCurrentExam(lastName, middleName, firstName, prefix, attrs.dob);
    } else {
      const updateAttrs = {
        name: createFullName(lastName.trim(), middleName.trim(), firstName.trim(), prefix.trim()),
        ...attrs
      };
      /* Optimistic update */
      const expectedPatient = { ...reportData.patient, ...updateAttrs }
      const backupReport = structuredClone(reportData);
      dispatchNewReportData({ event: "internal", data: { patient: expectedPatient } });
      /* Real update */
      examinationContext.updatePatient(examinationContext.patient.id, updateAttrs).catch(() => setReportData(backupReport))
    }
  };

  const getPatientData = (reportData) => {
    return reportData?.patient
      ? {
        ...formatName(reportData.patient.name),
        ...reportData.patient
      }
      : {};
  };

  // Load examination's site
  useEffect(() => {
    ResourceApi.getSite().then(resp => {
      setExaminationSite(resp.data.data.find(s => s.id === examinationContext.examination?.site_id));
    });
  }, [examinationContext.examination?.site_id]);

  const convertMedicalHistoryOptionsToTree = (options) => {
    if (!options) return [];

    const newOptions = [{
      label: '',
      id: '',
      isRiskFactor: false,
    }, ...options.map(option => ({
      label: option.label[currentLanguage],
      id: option.value,
      isRiskFactor: !!option.is_risky,
    }))];
    return newOptions;
  }


  const refreshPlaceholders = async () => {
    let placeholders = {};
    if (
      !examinationContext.examination
      || !examinationContext.episode
      || !examinationContext.patient
    )
      return setPlaceholders(placeholders)

    const unusualChecklistItems = reportData.checklist_items?.filter(item => item.status === "unusual");
    const slides = examinationContext.examinationInstanceViews.map(slide => ({
      ...slide,
      unusual: unusualChecklistItems?.some(item => item.instance_view_id.includes(slide.id)),
    }));

    let medicalHistory = Object.values(examinationContext.medicalHistoryItems || {});

    if (reportData?.examination_data) {
      placeholders["examination.status"] = {
        value: reportData?.examination_data?.status,
        label: __("examinationReview.status." + reportData?.examination_data?.status),
        visible: getDataById("examination.status")?.visible ?? true,
        format: "string"
      };

      placeholders["examination.date"] = {
        value: getDataById("examination.date", convertTimeZone(reportData?.examination_data?.examination_date, examinationContext.examination?.site?.timezone || "UTC"))?.value.substring(0, 10),
        visible: getDataById("examination.date")?.visible ?? true,
        format: "date"
      };

      placeholders["examination.trimester"] = {
        value: getDataById("examination.trimester", reportData?.examination_data?.trimester)?.value,
        visible: getDataById("examination.trimester")?.visible ?? true,
        format: "string"
      };

      placeholders["examination.finding"] = {
        value: getDataById("examination.finding")?.value,
        visible: getDataById("examination.finding")?.visible ?? true,
        format: "multiple"
      }

      placeholders["examination.indication"] = {
        value: getDataById("examination.indication")?.value,
        visible: getDataById("examination.indication")?.visible ?? true,
        format: "multiple"
      }

      const signedData = reportData.examination_data.signed_data;
      if (signedData) {
        placeholders["examination.signed"] = {
          value: !!signedData.inserted_at,
          editable: false
        };
        placeholders["examination.signed.date"] = {
          value: signedData.frozen_inserted_at.date,
          visible: getDataById("examination.signed.date")?.visible ?? true,
          format: "date",
          editable: false
        };
        placeholders["examination.signed.time"] = {
          value: signedData.frozen_inserted_at.time,
          visible: getDataById("examination.signed.time")?.visible ?? true,
          format: "string",
          editable: false
        };
        placeholders["examination.signed.title"] = {
          value: signedData.entity_title,
          visible: getDataById("examination.signed.title")?.visible ?? true,
          format: "string",
          editable: false
        };
      }

      if (Array.isArray(reportData.examination_data?.signatories)) {
        const firstSignatory = reportData.examination_data?.signatories?.[0]

        if (firstSignatory) {
          placeholders["examination.signatories.first.date"] = {
            value: firstSignatory.frozen_inserted_at.date,
            visible: getDataById("examination.signatories.first.date")?.visible ?? true,
            format: "date",
            editable: false
          };
          placeholders["examination.signatories.first.time"] = {
            value: firstSignatory.frozen_inserted_at.time,
            visible: getDataById("examination.signatories.first.time")?.visible ?? true,
            format: "string",
            editable: false
          };
          placeholders["examination.signatories.first.title"] = {
            value: firstSignatory.entity_title,
            visible: getDataById("examination.signatories.first.title")?.visible ?? true,
            format: "string",
            editable: false
          };
        }

        const signatoriesCount = reportData.examination_data.signatories.length
        const lastSignatory = signatoriesCount !== 0 ? reportData.examination_data.signatories[signatoriesCount - 1] : undefined

        if (lastSignatory) {
          placeholders["examination.signatories.last.date"] = {
            value: lastSignatory.frozen_inserted_at.date,
            visible: getDataById("examination.signatories.last.date")?.visible ?? true,
            format: "date",
            editable: false
          };
          placeholders["examination.signatories.last.time"] = {
            value: lastSignatory.frozen_inserted_at.time,
            visible: getDataById("examination.signatories.last.time")?.visible ?? true,
            format: "string",
            editable: false
          };
          placeholders["examination.signatories.last.title"] = {
            value: lastSignatory.entity_title,
            visible: getDataById("examination.signatories.last.title")?.visible ?? true,
            format: "string",
            editable: false
          };
        }

        placeholders["examination.revisers.count"] = {
          value: (signatoriesCount || 1) - 1,
          format: "number",
          visible: getDataById("examination.revisers.count")?.visible ?? true,
          editable: false
        }

        placeholders["examination.revised"] = {
          value: reportData.examination_data.signatories.length > 1,
          format: "boolean",
          visible: getDataById("examination.revised")?.visible ?? true,
          editable: false
        };

        placeholders["examination.signatories"] = {
          value: Object.assign({}, reportData.examination_data.signatories.map((s) => ({
            date: s.frozen_inserted_at.date,
            time: s.frozen_inserted_at.time,
            title: s.entity_title,
          }))),
          visible: getDataById("examination.signatories")?.visible ?? true,
          format: "multiple",
          editable: false
        }

        placeholders["examination.revisers"] = {
          value: Object.assign({}, reportData.examination_data.signatories.slice(1).map((s) => ({
            date: s.frozen_inserted_at.date,
            time: s.frozen_inserted_at.time,
            title: s.entity_title,
          }))),
          visible: getDataById("examination.revisers")?.visible ?? true,
          format: "multiple",
          editable: false
        }
      }


      const submittedData = reportData.examination_data.submitted_data;
      if (submittedData) {
        placeholders["examination.submitted"] = {
          value: !!submittedData.inserted_at,
        };
        placeholders["examination.submitted.date"] = {
          value: getDataById("examination.submitted.date", convertTimeZone(submittedData.inserted_at, examinationContext.examination.site.timezone).substring(0, 10))?.value,
          visible: getDataById("examination.submitted.date")?.visible ?? true,
          format: "date"
        };
        const submittedTimeValue = getDataById("examination.submitted.time", convertTimeZone(submittedData.inserted_at, examinationContext.examination.site.timezone).substring(11))?.value;
        placeholders["examination.submitted.time"] = {
          value: timeFormatter(submittedTimeValue, appContext?.preferences?.time_format),
          visible: getDataById("examination.submitted.time")?.visible ?? true,
          format: "string"
        };
        placeholders["examination.submitted.title"] = {
          value: submittedData.entity_title,
          visible: getDataById("examination.submitted.title")?.visible ?? true,
          format: "string"
        };
      }

      if (reportData?.examination_data?.imported_id) placeholders["examination.import.id"] = { value: reportData?.examination_data?.imported_id };
      if (reportData?.examination_data?.import_source) placeholders["examination.import.source"] = { value: reportData?.examination_data?.import_source };
      if (reportData?.examination_data?.imported_data) {
        Object.entries(reportData?.examination_data?.imported_data).forEach(([key, value]) => {
          placeholders[`examination.import.${key}`] = { value };
        });
      }

      if (reportData?.patient?.imported_id) placeholders["patient.import.id"] = { value: reportData?.patient?.imported_id };
      if (reportData?.patient?.imported_id) placeholders["patient.import.source"] = { value: reportData?.patient?.import_source };

      const stakeholders = reportData.examination_data.associated_contact_points;
      if (stakeholders) {
        placeholders["examination.stakeholders"] = {
          value: Object.assign({}, stakeholders.map(stakeholder => ({
            ...(stakeholder.contact_point || {}),
            name: stakeholder.contact_point.name ? formatName(stakeholder.contact_point.name).fullName : "",
            address: __("examinationStakeholder.fullAddress", stakeholder.contact_point?.address).replace(/[ ,\(\)]+$/g, "").replace(/^[ ,\(\)]+/g, ""),
            role: __(`examinationStakeholder.role.external.${stakeholder.role}`),
          }))),
          visible: getDataById("examination.stakeholders")?.visible ?? true,
          format: "multiple",
          editable: false
        }

        placeholders["examination.external_hospitals"] = {
          value: Object.assign({}, stakeholders.filter(stakeholder => stakeholder.role !== "referring_provider").map(stakeholder => ({
            ...(stakeholder.contact_point || {}),
            name: stakeholder.contact_point.name ? formatName(stakeholder.contact_point.name).fullName : "",
            address: __("examinationStakeholder.fullAddress", stakeholder.contact_point?.address).replace(/[ ,\(\)]+$/g, "").replace(/^[ ,\(\)]+/g, ""),
            role: __(`examinationStakeholder.role.external.${stakeholder.role}`),
          }))),
          visible: getDataById("examination.external_hospitals")?.visible ?? true,
          format: "multiple",
          editable: false
        }

        const referringPhysician = stakeholders.find(stakeholder => stakeholder.role === 'referring_provider');
        if (referringPhysician?.contact_point) {
          placeholders["referring_physician.name"] = {
            value: getDataById("referring_physician.name", referringPhysician.contact_point.name ? formatName(referringPhysician.contact_point.name).fullName : "")?.value || "",
            visible: getDataById("referring_physician.name")?.visible ?? true,
            format: "string"
          };
          placeholders["referring_physician.phone"] = {
            value: getDataById("referring_physician.phone", referringPhysician.contact_point.phone)?.value || "",
            visible: getDataById("referring_physician.phone")?.visible ?? true,
            format: "string"
          };
          placeholders["referring_physician.fax"] = {
            value: getDataById("referring_physician.fax", referringPhysician.contact_point.fax)?.value || "",
            visible: getDataById("referring_physician.fax")?.visible ?? true,
            format: "string"
          };
          placeholders["referring_physician.email"] = {
            value: getDataById("referring_physician.email", referringPhysician.contact_point.email)?.value || "",
            visible: getDataById("referring_physician.email")?.visible ?? true,
            format: "string"
          };
          placeholders["referring_physician.id"] = {
            value: getDataById("referring_physician.id", referringPhysician.contact_point.external_id)?.value || "",
            visible: getDataById("referring_physician.id")?.visible ?? true,
            format: "string"
          };
          placeholders["referring_physician.address"] = {
            value: __("examinationStakeholder.fullAddress", referringPhysician.contact_point?.address || {}).replace(/[ ,\(\)]+$/g, "").replace(/^[ ,\(\)]+/g, ""),
            visible: getDataById("referring_physician.address")?.visible ?? true,
            format: "string"
          };
        }
      }

      const entities = reportData.examination_data.entities;
      if (entities) {
        placeholders["examination.entities"] = {
          value: Object.assign({}, entities.map(entity => ({
            name: entity.entity?.title,
            country: entity.entity?.country_id,
            role: __(`examinationStakeholder.role.${entity.role}`),
          }))),
          visible: getDataById("examination.entities")?.visible ?? true,
          format: "multiple",
          editable: false
        }
      }
    }

    if (reportData?.site) {
      placeholders["site.name"] = {
        value: getDataById("site.name")?.value,
        visible: getDataById("site.name")?.visible ?? true,
      };
      placeholders["site.address"] = {
        value: getDataById("site.address")?.value,
        visible: getDataById("site.address")?.visible ?? true,
      };
      placeholders["site.notes"] = {
        value: getDataById("site.notes")?.value,
        visible: getDataById("site.notes")?.visible ?? true,
      };
      placeholders["site.phone"] = {
        value: getDataById("site.phone_number")?.value,
        visible: getDataById("site.phone_number")?.visible ?? true,
        format: "string"
      };
      placeholders["site.website"] = {
        value: getDataById("site.website")?.value,
        visible: getDataById("site.website")?.visible ?? true,
        format: "string"
      };
    }

    // site.logo is for patient app logo
    // site.header_image is for printing headers on both Report and slides Printing
    // leaving placeholder intact since logo were used as header image for Report
    if (examinationSite?.header_image_base64) {
      placeholders["logo.url"] = `data:image/png;base64, ${examinationSite?.header_image_base64}`;
    }

    if (reportData?.practitioner) {
      placeholders["practitioner.name"] = {
        value: getDataById("practitioner.title")?.value || "",
        visible: getDataById("practitioner.title")?.visible ?? true,
        format: "string"
      };
      placeholders["practitioner.rpps"] = {
        value: getDataById("practitioner.rpps")?.value || "",
        visible: getDataById("practitioner.rpps")?.visible ?? true,
        format: "string"
      };
      placeholders["practitioner.id"] = placeholders["practitioner.rpps"];
    }

    if (reportData?.reader) {
      placeholders["reader.name"] = {
        value: getDataById("reader.title")?.value || "",
        visible: getDataById("reader.title")?.visible ?? true,
        format: "string"
      };
      placeholders["reader.rpps"] = {
        value: getDataById("reader.rpps")?.value || "",
        visible: getDataById("reader.rpps")?.visible ?? true,
        format: "string"
      };
      placeholders["reader.id"] = placeholders["reader.rpps"];
    }

    if (reportData?.patient) {
      const patientGa = getDataById("patient.ga", getDataById("v2_dating.value") ? getNiceGestionalAgeFromDays(__, getDataById("v2_dating.value").value) : "");
      const conceptionDate = getDataById("patient.conception_date", reportData?.patient?.conception_date ? reportData?.patient?.conception_date : "");
      const lmpDate = getDataById("patient.lmp_date", reportData?.patient?.lmp_date ? reportData?.patient?.lmp_date : "");
      const fetusSex = getDataById("fetus.sex", reportData?.patient["medicalexam.fetus.sex"]?.value);
      const fetusSexVisibility = getDataById("fetus.sex_visibility", reportData?.examination_data?.["fetus_sex_visibility"]);
      const fetusPosition = getDataById("fetus.position");
      const placentaPosition = getDataById("placenta.position");

      placeholders["patient.conception_date"] = {
        value: conceptionDate?.value,
        visible: conceptionDate?.visible ?? true,
        format: "date"
      };
      placeholders["patient.lmp_date"] = {
        value: lmpDate?.value,
        visible: lmpDate?.visible ?? true,
        format: "date"
      };
      placeholders["patient.ga"] = {
        value: patientGa?.value,
        visible: patientGa?.visible ?? true,
        format: "string"
      };


      placeholders["patient.nb_fetuses"] = { value: examinationContext.examination.nb_fetus };

      placeholders["fetus.number"] = { value: fetuses.map((f) => f.dicom_id) };
      placeholders["fetus.name"] = { value: fetuses.map((f) => f.fetus?.label || "-") };
      placeholders["fetus.examination_id"] = { value: fetuses.map((f) => f.examination_id) };
      placeholders["fetus.episode_id"] = { value: fetuses.map((f) => f.fetus?.id) };

      placeholders["fetus.sex_visibility"] = {
        value: fetusSexVisibility.value,
        visible: fetusSexVisibility?.visible ?? true,
        format: "string"
      };

      placeholders["fetus.sex"] = {
        ...(fetusSex || {}),
        label: examinationContext.medicalHistoryItems?.["medicalexam.fetus.sex"]?.label?.[currentLanguage],
        value: fetuses.map(f => fetusSexVisibility.value === "hidden" ? "" : f?.fetus?.sex),
        comment: (fetusSexVisibility.value === "masked") ? __("report.fetusSexHidden.comment") : fetusSex?.comment || "",
        visible: fetusSexVisibility.value === "visible" && fetusSex?.visible,
        allowUpdatesWhenHidden: fetusSexVisibility.value === "masked",
        showOptions: fetusSexVisibility.value === "visible",
        tree: [
          {
            id: "",
            label: "",
          },
          {
            id: "male",
            label: __("report.fetusSex.male"),
          },
          {
            id: "female",
            label: __("report.fetusSex.female"),
          },
          {
            id: "unknown",
            label: __("report.fetusSex.unknown"),
          },
        ],
        format: "string"
      };

      let fetusPositionTree = reportData?.fetus_positions?.map(position => ({
        label: position.label?.[currentLanguage],
        id: position.id,
        order: position.order,
      }))
        .sort((a, b) => {
          return a.order - b.order;
        });

      fetusPositionTree = fetusPositionTree?.reduce((tree, position) => {
        if (!position?.label?.includes("–")) {
          tree.push(position);
          return tree;
        }
        const [category, subposition] = position.label.split("–");
        let categoryIndex = tree.findIndex(item => item.label === category);
        if (categoryIndex === -1) {
          categoryIndex = tree.length;
          tree.push({
            label: category,
            selectable: false,
            id: category,
            tree: [],
          });
        }
        tree[categoryIndex].tree.push({
          label: subposition.trim(),
          id: position.id,
        });
        return tree;
      }, []);

      placeholders["fetus.position"] = {
        ...(fetusPosition || {}),
        label: __("examinationReview.fetusPosition"),
        value: fetuses.map((f) => (f.position_id)),
        visible: getDataById("fetus.position")?.visible ?? true,
        tree: fetusPositionTree || [],
        format: "number"
      };

      placeholders["placenta.position"] = {
        ...(placentaPosition || {}),
        value: fetuses.map((f) => (f.placenta_position_id)),
        visible: getDataById("placenta.position")?.visible ?? true,
        tree: reportData?.placenta_positions?.map(position => ({
          label: position.label[currentLanguage],
          id: position.id,
        })) || [],
        format: "number"
      };


      placeholders["episode.estimated_delivery_date"] = { value: reportData?.episode?.estimated_delivery_date };
      placeholders["episode.conception_date"] = { value: reportData?.episode?.conception_date };
      placeholders["episode.conception_method"] = { value: reportData?.episode?.conception_method };
      placeholders["episode.lmp_date"] = { value: reportData?.episode?.lmp_date };
      placeholders["episode.cycle_length"] = { value: reportData?.episode?.cycle_length };
      placeholders["episode.embryo_transfer_date"] = { value: reportData?.episode?.embryo_transfer_date };
      placeholders["episode.embryo_transfer_day"] = { value: reportData?.episode?.embryo_transfer_day };
      placeholders["episode.prev_ultrasound_exam_date"] = { value: reportData?.episode?.prev_ultrasound_exam_date };
      placeholders["episode.prev_ultrasound_ga"] = { value: reportData?.episode?.prev_ultrasound_ga };
      placeholders["episode.prev_ultrasound_biometry_value"] = { value: reportData?.episode?.prev_ultrasound_biometry_value };
      placeholders["episode.prev_ultrasound_option"] = { value: reportData?.episode?.prev_ultrasound_option };
      placeholders["episode.edd_methods"] = { value: reportData?.episode?.edd_methods };

      placeholders["medicalexam.fetus.conception_date"] = placeholders["episode.conception_date"];
      placeholders["medicalexam.fetus.lmp"] = placeholders["episode.lmp_date"];
      placeholders["medicalexam.fetus.edd"] = placeholders["examination.edd"];
      placeholders["medicalexam.fetus.embryo_transfer_date"] = placeholders["episode.embryo_transfer_date"];
      placeholders["medicalexam.fetus.prev_ultrasound_exam_date"] = placeholders["episode.prev_ultrasound_exam_date"];
    };


    /* Measurements */
    for (const [fetus, measurements] of Object.entries(reportData?.measurements?.fetus || {})) {
      for (const [measurementId, measurement] of Object.entries(measurements || {})) {
        const id = `measurement.${measurementId}`;
        if (!placeholders[id]) placeholders[id] = [];
        const fetusNumber = Number(fetus);
        const placeholder = getMeasurementObject(id, measurementId, measurement, fetusNumber);
        placeholders[id][fetusNumber] = {
          ...placeholder,
          fetus: fetusNumber,
          editor: (data) => <EditBiometry id={measurementId} fetus={fetusNumber} data={{ ...placeholder, ...data }} reportDataOptions={reportDataOptions} close={onEndEditing} />
        };
      }
    }

    for (const [measurementId, measurement] of Object.entries(reportData?.measurements?.patient || {})) {
      const id = `measurement.${measurementId}`;
      const placeholder = getMeasurementObject(id, measurementId, measurement, "patient");
      if (!placeholders[id]) placeholders[id] = [];
      placeholders[id][0] = {
        ...placeholder,
        editor: (data) => <EditBiometry id={measurementId} fetus={"patient"} data={{ ...placeholder, ...data }} reportDataOptions={reportDataOptions} close={onEndEditing} />
      };
    }

    /* dating */
    if (reportData?.v2_dating) {
      placeholders["ga.assigned.exam"] = getDataById("v2_dating.assigned_exam_id");
      placeholders["ga.assigned.method"] = getDataById("v2_dating.assigned_method_id");
      placeholders["ga.assigned.fetus"] = getDataById("v2_dating.assigned_fetus");
      placeholders["ga.assigned.value"] = getDataById("v2_dating.value");
      placeholders["ga.assigned.selected_at"] = getDataById(
        "v2_dating.selected_at",
        convertTimeZone(reportData?.v2_dating?.selected_at, examinationContext.examination?.site?.timezone || "UTC")
      );
    }
    if (reportData?.v2_dating_values) {
      for (const dating of reportData.v2_dating_values) {
        const {
          estimation,
          examination_id: examId,
          fetus,
          value,
          // TODO: source?
        } = dating;

        const id = `ga.${estimation}`;

        const dateObtained = placeholders?.["examination.date"]?.value;



        const episodeDatingSpecificData = {
          "ga.lmp": { dateObtained: reportData?.episode?.lmp_date, cycleLength: reportData?.episode?.cycle_length },
          "ga.conception_date": { dateObtained: reportData?.episode?.conception_date, conceptionMethod: reportData?.episode?.conception_method },
          "ga.embryo_transfer": { dateObtained: reportData?.episode?.embryo_transfer_date, embryoTransferDay: reportData?.episode?.embryo_transfer_day },
          "ga.edd": { dateObtained: reportData?.episode?.estimated_delivery_date },
          "ga.prev_ultrasound": { dateObtained: reportData?.episode?.prev_ultrasound_exam_date, prevUltrasoundGa: reportData?.episode?.prev_ultrasound_ga },
        };

        const specificData = episodeDatingSpecificData[id] || {};

        const isAssigned = (
          // episodeGas are not related to any particular exam
          (examId == placeholders["ga.assigned.exam"]?.value || Object.keys(episodeDatingSpecificData).includes(id))
          && id === placeholders["ga.assigned.method"]?.value
          && fetus == placeholders["ga.assigned.fetus"]?.value);
        if (placeholders[id] === undefined) placeholders[id] = [];

        placeholders[id][Number(fetus)] = {
          value,
          examId,
          dateObtained,
          method: estimation,
          isAssigned,
          fetus,
          ...specificData
        };
      }
    };

    /* checklist */
    const inferChecklistItemStatus = (itemHasAnImage, itemShouldHaveAnImage, item) => {
      if (!isNullOrUndefined(item.status))
        return item.status;
      if (itemHasAnImage)
        return 'usual';
      if (itemShouldHaveAnImage)
        return 'non_visualized';
      return 'not_applicable';
    }

    const isChecklistItemPartOfTheExam = (item) => {
      const mandatoryIndex = mandatoryChecklistSlugs.indexOf(item.slug);
      if (mandatoryIndex >= 0) return mandatoryIndex + 1;
      if (item.status !== null && item.status !== "not_applicable") return true;
      return false;
    }

    const optionalViews = examinationContext.instances.map(instance => examinationContext.examinationInstanceViews.some(view => view.id === instance.slideId) ? false : instance.slideId).filter(i => i);

    for (const item of (reportData.checklist_items || [])) {
      const itemHasAnImage = examinationContext.instances
        .some(instance => item.instance_view_id?.includes(instance.slideId)) && (mandatoryChecklistSlugs.includes(item.slug) || optionalViews.includes(item.slideId));

      const itemShouldHaveAnImage = slides
        .some(slide => item.instance_view_id?.includes(slide.id)) && mandatoryChecklistSlugs.includes(item.slug);

      placeholders[`checklist.item.${item.slug}`] = fetuses.map((f, fetusIndex) => {
        const userDefinedStatus = reportData.checklist_items_with_statuses?.find(dynItem => dynItem.id === item.id && dynItem.examination_fetus_id == f.id)?.status || null;
        const itemStatus = inferChecklistItemStatus(itemHasAnImage, itemShouldHaveAnImage, { ...item, status: userDefinedStatus });
        const itemOrder = isChecklistItemPartOfTheExam({ ...item, status: userDefinedStatus });

        /* TODO if required we can also only duplicate item per fetus if origin = fetal
        * For the moment it is not required as checklist item displayed in the report are
        * defined in the report template/config
        */
        const userEdits = getDataById(`checklist.item.${item.slug}`)?.[f.dicom_id] || {};

        let newItem = {
          ...item,
          ...userEdits,
          visible: userEdits?.visible ?? true,
          examination_fetus_id: f.id,
          status: itemStatus,
          active: !!itemOrder,
          order: itemOrder,
          mandatory: mandatoryChecklistSlugs.includes(item.slug),
        }

        for (const [componentUID, comments] of Object.entries(autogeneratedChecklistComments[fetusIndex] || {})) {
          const key = `comment${componentUID === "fetal_anatomy" ? "" : "_" + componentUID}`;
          if (isNullOrUndefined(newItem[key]) || newItem[key] === false) {
            newItem[key] = comments?.find(comment => comment.data === `checklist.item.${item.slug}` && comment.attribute === "comment")?.content;
          }
        };

        return {
          ...newItem,
          group_id: item.assoc_checklist_item_checklist_item_group?.checklist_item_group_id,
          status: itemStatus,
          normal: itemStatus === 'usual',
          unusual: itemStatus === 'unusual',
          viewed: itemStatus !== 'non_visualized',
        }
      });
    }

    for (const item of (reportData?.checklist_items?.filter(c => c.parents?.length > 0) || [])) {
      for (const parent of item.parents) {
        if (item.unusual) {
          const id = `checklist.item.${parent.slug}`;
          if (placeholders[id]) {
            for (const f of fetuses) {
              placeholders[id][f].active = true;
              placeholders[id][f].normal = item.normal;
              placeholders[id][f].unusual = item.unusual;
              placeholders[id][f].children = [...(placeholders[id][f]?.children || []), item];
            }
          }
        }
      }
    }


    placeholders["views"] = { normal: true, viewed: true, elements: 0 };
    placeholders["images"] = { mediaIds: [] };
    placeholders[`images.${appContext?.preferences?.default_category.toLowerCase()}`] = { mediaIds: [] };

    /* The first view is always undefined for some reason. We ensure we only keep valid slides */
    const allSlides = [...examinationContext.instanceViews].filter((view) => view?.id)

    for (const view of allSlides) {
      placeholders[`views`].elements++;
      const medias = examinationContext.instances.filter(media => media.slideId === view.id);
      const viewSlug = (view.default_techno !== "us" ? view.default_techno + "." : "") + getSlugFromLabel(view.label?.["en"]);

      if (!placeholders[`view.${viewSlug}`]) {
        placeholders[`view.${viewSlug}`] = {
          normal: !reportData.checklist_items?.filter(c => c.instance_view_id?.includes(view.id))?.some(c => c?.status === "unusual"),
          viewed: !!medias.length,
          mediaIds: medias.map(media => media.id),
          techno: view.techno,
        };
      }

      if (appContext?.preferences?.default_category && view?.category?.[trimester]?.some(category => category.includes(appContext.preferences.default_category))) {
        placeholders[`images.${appContext.preferences.default_category.toLowerCase()}`].mediaIds.push(view.mediaId);
      }
    }

    if (medicalHistory) {

      const itemFormatFromMHType = (type) => {
        switch (type) {
          case "multiselect": return "multiple";
          case "buttongroup":
          case "text":
          case "select":
            return "string";
          case "date":
            return "date";
          case "number":
            return "number";
          default:
            return "string";
        }
      }

      const getOptionLabel = (item, value) => {
        if (!item.options?.length) return value;
        if (Array.isArray(value)) return value.map(value => item.options.find(option => option.value === value)?.label[currentLanguage]);
        return item.options.find(option => option.value === value)?.label[currentLanguage];
      }


      for (const item of medicalHistory) {
        const id = (
          item.text_id?.startsWith('patient.')
            ? item.text_id
            : `medical-history.${item.text_id}`
        );
        if (id === "medical-history.medicalexam.mother.height") {
          const patientHeight = getDataById("medical-history.medicalexam.mother.height");
          placeholders["medical-history.medicalexam.mother.height"] = {
            value: examinationContext.examination.medical_history?.["medicalexam.mother.height"]?.raw_value,
            units: "cm",
            visible: patientHeight?.visible ?? true,
            format: "number"
          };

        } else if (id === "medical-history.medicalexam.mother.weight") {
          const patientWeight = getDataById("medical-history.medicalexam.mother.weight");
          placeholders["medical-history.medicalexam.mother.weight"] = {
            value: examinationContext.examination.medical_history?.["medicalexam.mother.weight"]?.raw_value,
            units: "kg",
            visible: patientWeight?.visible ?? true,
            format: "number"
          };

        } else {
          const user_edit = getDataById(id);
          placeholders[id] = {
            ...user_edit,
            /* For the moment this medical history is equivalent to reportData medical history */
            value: examinationContext.examination.medical_history?.[item.text_id]?.value ?? item.default,
            raw_value: examinationContext.examination.medical_history?.[item.text_id]?.raw_value,
            is_risky: examinationContext.examination.medical_history?.[item.text_id]?.is_risky,
            label: getOptionLabel(item, examinationContext.examination.medical_history?.[item.text_id]?.value ?? item.default),
            fieldLabel: item.label[currentLanguage],
            tree: convertMedicalHistoryOptionsToTree(item.options),
            type: item.type,
            format: itemFormatFromMHType(item.type),
            visible: user_edit?.visible ?? true,
          }
        }
      }

      const patientData = getPatientData(reportData)
      Object.entries(patientView).map(([id, { view }]) => {
        placeholders[id] = view(patientData, id, reportData)
      });

      const medicalHistoryStandardKeys = medicalHistory.map(({ text_id }) => text_id)
      for (const [key, item] of Object.entries(examinationContext.examination.medical_history || {})) {
        if (medicalHistoryStandardKeys.indexOf(key) == -1 && !patientView[key]) {
          /* This means the field is a custom medical history field */
          for (const id of medicalHistoryIdToTemplateIds(key)) {
            const user_edit = getDataById(id);
            placeholders[id] = {
              ...user_edit,
              value: item.value
            }
          }
        }
      }

      if (examinationContext.examination.medical_history?.["medicalexam.mother.weight"]?.raw_value && examinationContext.examination.medical_history?.["medicalexam.mother.height"]?.raw_value) {
        if (!placeholders["medical-history.medicalexam.mother.bmi"]) {
          placeholders["medical-history.medicalexam.mother.bmi"] = {
            format: "number",
          };
        }
        const computedBmi = computeBMI(examinationContext.examination?.medical_history["medicalexam.mother.weight"]?.raw_value, examinationContext.examination?.medical_history["medicalexam.mother.height"]?.raw_value);
        placeholders["medical-history.medicalexam.mother.bmi"].computed = computedBmi;
        placeholders["medical-history.medicalexam.mother.bmi"].value = placeholders["medical-history.medicalexam.mother.bmi"]?.value || computedBmi;
      }
    }

    // TODO: declare multiselect fields in Directus
    const slugsMultiselect = [
      'medical-history.episode.antibody',
      'medical-history.episode.antid',
      'medical-history.episode.bloodtest',
      'medical-history.episode.fetaltest',
      'medical-history.episode.previous_exam',
      'medical-history.episode.antenatal_booking',
    ];

    for (const slug of slugsMultiselect) {
      if (placeholders[slug]) {
        placeholders[slug].format = "multiple";
      }
    }

    if (reportData?.medical_history) {
      placeholders["medical-history"] = {
        normal: !(reportData.medical_history?.risk_factors?.some(item => !!item.is_risky) || Object.values(reportData.medical_history?.teratogenic_risks || {}).some(item => !!item.risk_factor_id)),
        viewed: (!!reportData.medical_history?.risk_factors?.length || !!Object.values(reportData.medical_history?.teratogenic_risks || {}).length),
      };
    }

    if (isFeatureFlagEnabled("sonio.report_dropdown_options")) {
      const normalizeTree = tree => tree.map(node => ({
        ...node,
        id: node.slug,
        tree: normalizeTree(node.tree || []),
      }));

      for (const [collectionName, collection] of Object.entries(dynamicDropdowns)) {
        placeholders[collectionName] = {
          ...getDataById(collectionName),
          tree: normalizeTree(collection?.tree || []),
          isDynamic: true,
          format: collection.multiple ? "multiple" : "string"
        };

        // if the selected option is nested in a sub-node, load the full tree
        if (!collection.loaded && !collection.loading && !isNullOrUndefined(placeholders[collectionName].value) && !collection.tree.some(node => node.slug === placeholders[collectionName].value)) {
          loadDynamicDropdownFullTree(collectionName);
        }
      }
    } else {
      if (reportData?.nested_collections) {
        for (const [collectionName, collection] of Object.entries(reportData.nested_collections)) {
          placeholders[`examination.${collectionName}`] = {
            ...getDataById(`examination.${collectionName}`),
            tree: collection?.full_tree || [],
            format: "multiple"
          };
        }
      }
    }

    placeholders["fetal_growth"] = reportData.report?.user_edits?.["fetal_growth"]


    /* Unmapped Measurements */
    const unmapped = {}
    for (const [index, entry] of (reportData?.dynamic_measurements || []).entries()) {
      const v = {
        value: true,
        measurement_value: entry?.value,
        unit: entry?.unit,
        identifier: entry?.identifier,
        measurement: entry?.raw_measurement?.name,
        derivation: entry?.raw_derivation?.name,
        site: entry?.raw_site?.name,
        laterality: entry?.raw_laterality?.name,
      }
      unmapped[index] = v
    }
    placeholders["measurements.unmapped"] = {
      value: unmapped,
      editable: false
    }

    // Identifier Measurements
    for (const [slug, identifierData] of (Object.entries(reportData?.identifier_measurements || {}))) {
      const identifierEdits = measurementsContext?.measurementData?.measurement_edits?.identifier?.[slug] || {};

      const valueEdits = Object.entries(identifierEdits || {}).filter(([k, _]) => !["visible", "comment", "value"].includes(k));

      for (const [measurement, { value }] of valueEdits) {
        if (!identifierData.hasOwnProperty(measurement)) identifierData[measurement] = {};
        identifierData[measurement].value = value;
      }
      placeholders[`identifier_measurement.${slug}`] = {
        measurements: identifierData,
        visible: identifierEdits?.visible ?? true,
        comment: identifierEdits?.comment ?? "",
        source: identifierData?.source ?? "dicom",
      };
    }

    setPlaceholders(placeholders);
  };

  const updateEpisode = async (id, newValue) => {
    let field = id;
    switch (id) {
      case "edd":
        field = "estimated_delivery_date";
        break;
      case "lmp":
        field = "lmp_date";
        break;
    }

    try {
      measurementsContext.optimisticUpdateEpisode({ [field]: newValue });
      await examinationContext.updateEpisode({ [field]: newValue });
      return true;
    }
    catch (error) {
      console.error(error)
      notificationContext.showNotification(<><Icon name="warning" />{" "}{__("report.unableToUpdate")}</>, 5000);
      return false;
    }
  };

  const createPatientAndAssignToCurrentExam = async (lastName, middleName, firstName, prefix, dob) => {
    /* This kind of operation should be done fully in the backend with an API such as
     *  /patient/create_and_attach/:examination_id
    */
    const patient = await examinationContext.createPatient({ firstName, lastName, dob });
    examinationContext.updateExamination({ patient_id: patient.id, episode_id: patient.current_episode_id })
  };

  const startEditingField = (placeholder, override = false) => {
    if (!examinationContext.canEdit) return;
    setEditingFieldId(placeholder);
  };

  /* batch version of onEndEditing */
  const applyChanges = async (slugs, opts) => {
    setEditingFieldId(false);
    if (!examinationContext.canEdit) return;

    const changes = {
      userEdits: {},
      examination_fetus: {},
      episode_fetus: {},
      episode: [],
      patient: {},
      medicalHistory: [],
      measurement: [],
    }

    // group changes
    for (let [id, newChanges] of Object.entries(slugs).filter(slug => slug[1])) {

      let storeValueInUserEdits = true;
      let storeValueInMedicalHistory = true;

      if ((Object.keys(patientView).indexOf(id) != -1) && !isNullOrUndefined(newChanges?.value)) {
        storeValueInUserEdits = false;
        storeValueInMedicalHistory = false;
        changes.patient = patientView[id].controller(newChanges.value, changes.patient)
      }

      if (id.startsWith('measurement.') || id.startsWith('identifier_measurement.')) {
        storeValueInUserEdits = false;
        storeValueInMedicalHistory = false;
        changes.measurement.push({ id, newChanges });
      }


      if (id === "fetus.sex") {
        storeValueInUserEdits = false;
        storeValueInMedicalHistory = false;

        if (Array.isArray(newChanges.value)) {
          newChanges
            .value
            .slice(1)
            .map((sex, index) => {
              const fetus = examinationContext.examination.fetuses[index].fetus;
              sex = (sex || "").toLowerCase();
              if (["unknown", "male", "female", ""].includes(sex) && sex != fetus.sex) {
                changes.episode_fetus[fetus.id] = { ...(changes.episode_fetus[fetus.id] || {}), sex };
              }
            });
        } else {
          const fetus = examinationContext.examination.fetuses[0].fetus;
          if (newChanges.value != fetus.sex) {
            changes.episode_fetus[fetus.id] = { ...(changes.episode_fetus[fetus.id] || {}), sex: newChanges.value };
          }
        }
      }

      if (id === "fetus.position") {
        storeValueInUserEdits = false;
        storeValueInMedicalHistory = false;
        if (Array.isArray(newChanges.value)) {
          newChanges
            .value
            .slice(1)
            .map((position_id, index) => {
              const fetus = examinationContext.examination.fetuses[index];
              if (position_id != fetus.position_id) {
                changes.examination_fetus[fetus.id] = { ...(changes.examination_fetus[fetus.id] || {}), position_id };
              }
            });
        } else {
          const fetus = examinationContext.examination.fetuses[0];
          const position_id = newChanges.value;
          if (position_id && position_id != fetus?.position_id) {
            changes.examination_fetus[fetus.id] = { ...(changes.examination_fetus[fetus.id] || {}), position_id };
          }
        }
      }

      if (id === "placenta.position" && !isNullOrUndefined(newChanges?.value)) {
        storeValueInUserEdits = false;
        storeValueInMedicalHistory = false;
        if (Array.isArray(newChanges.value)) {
          newChanges
            .value
            .slice(1)
            .map((placenta_position_id, index) => {
              const fetus = examinationContext.examination.fetuses[index];
              if (placenta_position_id != fetus.placenta_position_id) {
                changes.examination_fetus[fetus.id] = { ...(changes.examination_fetus[fetus.id] || {}), placenta_position_id };
              }
            });
        } else {
          const fetus = examinationContext.examination.fetuses[0];
          if (placenta_position_id != fetus.placenta_position_id) {
            changes.examination_fetus[fetus.id] = { ...(changes.examination_fetus[fetus.id] || {}), placenta_position_id };
          }
        }
      }

      const idMH = templateIdToMedicalHistoryId(id);

      if ([
        "episode.estimated_delivery_date",
        "episode.conception_date",
        "episode.conception_method",
        "episode.lmp_date",
        "episode.cycle_length",
        "episode.embryo_transfer_date",
        "episode.embryo_transfer_day",
        "episode.prev_ultrasound_exam_date",
        "episode.prev_ultrasound_ga",
        "episode.prev_ultrasound_biometry_value",
        "episode.prev_ultrasound_option",
        "episode.edd_methods",
      ].includes(idMH) && !isNullOrUndefined(newChanges?.value)) {
        storeValueInUserEdits = false;
        storeValueInMedicalHistory = false;
        changes.episode.push({ id: idMH.replace("episode.", ""), newChanges })
      }
      if (idMH && !isNullOrUndefined(newChanges?.value) && storeValueInMedicalHistory) {
        storeValueInUserEdits = false;
        const mhItem = medicalHistoryItem(id, examinationContext.medicalHistoryItems || {});
        changes.medicalHistory.push({ mhItem, newChanges });

        /* optimistic update */
        setPlaceholders(placeholders => {
          let newPlaceholders = { ...placeholders };
          if (newPlaceholders?.[id]) {
            newPlaceholders[id].value = newChanges?.value;
            newPlaceholders[id].raw_value = newChanges?.value;
          }
          return newPlaceholders;
        });
        if ([
          "medicalexam.fetus.conception_date",
          "medicalexam.fetus.lmp",
          "medicalexam.fetus.edd",
          "medicalexam.fetus.embryo_transfer_date",
          "medicalexam.fetus.prev_ultrasound_exam_date"
        ].includes(idMH) && !isNullOrUndefined(newChanges?.value)) {
          storeValueInUserEdits = false;
          storeValueInMedicalHistory = false;
          changes.episode.push({ id: idMH.replace("medicalexam.fetus.", ""), newChanges })
        }
        continue;
      }

      if (!id.startsWith("measurement.")) {
        for (const [key, value] of Object.entries(newChanges)) {
          let shouldSave = true;
          const ignoredKeys = ["fetus"];
          if (key === "value" && !storeValueInUserEdits) shouldSave = false;
          if (ignoredKeys.indexOf(key) !== -1) shouldSave = false;
          if (key !== "value" && isNullOrUndefined(value)) shouldSave = false;

          if (shouldSave) {
            if (!changes.userEdits[id]) changes.userEdits[id] = {};
            changes.userEdits[id][key] = value;
          }
        }
      }
    }

    // optimistic update
    if (Object.keys(changes.userEdits).length) {
      const backupReport = structuredClone(reportData);
      dispatchNewReportData({ event: "user_edits", data: changes.userEdits || {} });
      Object.entries(changes.userEdits).map(([slug, newChanges]) => {
        onEndEditingBIEvent(slug, newChanges, opts?.BIContext);
      })

      updateReport(examId, changes.userEdits, backupReport);
    }

    // apply changes
    for (let entry of changes.measurement) {
      /* BI is sent directly in the onEndEditingMeasurement function */
      onEndEditingMeasurement(entry.id, entry.newChanges, opts);
    }

    for (let [fetusId, change] of Object.entries(changes.examination_fetus)) {
      const fetus = examinationContext.examination.fetuses.find(f => f.id === parseInt(fetusId));
      const fetusIndex = examinationContext.examination.fetuses.findIndex(f => f.id === parseInt(fetusId)) + 1;
      if (fetus) {
        Object.entries(change).map(([identifier, value]) => {
          const identifiersToSlug = {
            position_id: "fetus.position",
            placenta_position_id: "placenta.position",
          };
          const slug = identifiersToSlug[identifier];
          onEndEditingBIEvent(slug, { value, fetus: fetusIndex, examination_fetus_id: fetus.id }, opts.BIContext);
        });
        examinationContext.updateExaminationFetus(fetus, change);
      }
    }

    for (let [fetusId, change] of Object.entries(changes.episode_fetus)) {
      const fetus = examinationContext.examination.fetuses.find(f => f.fetus.id === parseInt(fetusId))?.fetus;
      const fetusIndex = examinationContext.examination.fetuses.findIndex(f => f.fetus.id === parseInt(fetusId)) + 1;

      if (fetus) {
        Object.entries(change).map(([identifier, value]) => {
          const identifiersToSlug = {
            sex: "fetus.sex",
          }
          const slug = identifiersToSlug[identifier];
          onEndEditingBIEvent(slug, { value, fetus: fetusIndex, episode_fetus_id: fetus.id }, opts.BIContext);
        })
        examinationContext.updateEpisodeFetus(fetus, change);
      }
    }

    for (let entry of changes.medicalHistory) {
      const slug = entry.mhItem.text_id;
      onEndEditingBIEvent(slug, entry.newChanges, opts.BIContext);
      examinationContext.updateMedicalHistory(entry.mhItem, entry.newChanges?.value, entry.newChanges?.value);
    }

    for (let entry of changes.episode) {
      onEndEditingBIEvent("episode." + entry.id, entry.newChanges, opts.BIContext);
      updateEpisode(entry.id, entry.newChanges?.value);
    }

    if (Object.keys(changes.patient).length != 0) {
      for (let [key, value] of Object.entries(changes.patient)) {
        const identifiersToSlug = {
          firstName: "patient.firstname",
          lastName: "patient.lastname",
          middleName: "patient.middlename",
          title: "patient.title",
          fullName: "patient.name",
          dob: "patient.dob",
          address: "patient.address",
          sex: "patient.sex",
          email_address: "patient.mail",
        }
        const slug = identifiersToSlug[key];
        onEndEditingBIEvent(slug, { value }, opts.BIContext);
      }
      updatePatient(changes.patient)
    }
  }

  const onEndEditing = async (id, newChanges, custom = false, opts = {}) => {
    setEditingFieldId(false);
    if (!examinationContext.canEdit) return;

    if (custom) id = `custom.${id}`;

    applyChanges({ [id]: newChanges }, opts);
  };

  const onEndEditingDynamicDropdown = async (slug, newChanges, custom = false, opts = {}) => {
    setEditingFieldId(false);
    if (!examinationContext.canEdit) return;

    if (custom) slug = `custom.${slug}`;
    const dynamicDropdownId = dynamicDropdowns[slug]?.id;

    let enrichedChanges = null;

    if (dynamicDropdownId) {
      // get the full option
      const enrichValue = async (value, optionSlug) => (value && !value.loaded)
        ? {
          ...value,
          description: (await ResourceApi.getDynamicDropdownOption(examinationContext.examination.id, dynamicDropdownId, optionSlug))?.data?.value,
          loaded: true,
        }
        : value;

      if (typeof newChanges?.value === "object") {
        /* multi-choice dropdown */
        let enrichedValue = {};

        for (const [optionSlug, changes] of Object.entries(newChanges?.value)) {
          enrichedValue[optionSlug] = await enrichValue(changes, optionSlug);
        }

        enrichedChanges = {
          ...newChanges,
          value: enrichedValue,
        };

      } else if (Array.isArray(newChanges?.value)) {
        /* single-choice multi-fetal dropdown */
        // based on the current data-model, it's not possible to load descriptions here
        enrichedChanges = newChanges;

      } else {
        /* single-choice dropdown */
        enrichedChanges = {
          ...newChanges,
          description: enrichValue(changes.value)?.description
        };
      }

    } else {
      enrichedChanges = newChanges;
    }

    applyChanges({ [slug]: enrichedChanges }, opts);
  };

  const onEndEditingChecklist = async (value, checklistItem, opts = {}) => {
    if (!checklistItem) return;
    const checklistItems = Array.isArray(checklistItem) ? checklistItem : [checklistItem];

    // optimistic update
    let optimisticChecklistItems = structuredClone(reportData.checklist_items_with_statuses);
    for (const item of checklistItems) {
      const checklist_item = { ...item, status: value };
      optimisticChecklistItems = updateChecklistItemStatus(optimisticChecklistItems, checklist_item);
    }
    dispatchNewReportData({ event: "checklist_items_with_statuses", data: optimisticChecklistItems })
    /* this is not super optimized. We can improve this by moving this operation as one single API call */

    const localBIContext = opts.BIContext ? opts.BIContext : { ...BIContext, page: "checklist-items" };
    checklistItems.forEach(({ slug, fetus, examination_fetus_id }) => {
      onEndEditingBIEvent(`checklist.item.${slug}`, { value, fetus, examination_fetus_id }, localBIContext);
    });

    try {
      await ResourceApi.updateChecklistItemStatus(examinationContext.examination.id, checklistItems.map(item => item.id), value, checklistItems?.[0]?.examination_fetus_id);
      loadDynamicReportData();
    }
    catch (error) {
      loadDynamicReportData();
      notificationContext.showNotification(<><Icon name="warning" />{" "}{__("report.unableToUpdate")}</>, 5000);
    }
  };

  const onEndEditingMeasurement = (id, newChanges, opts = {}) => {
    if (newChanges.fetus == 0) newChanges.fetus = "patient"

    if (id.startsWith("measurement.")) {
      if (!isNullOrUndefined(newChanges.value)) {
        const values = { edit: newChanges.value };
        newChanges.values = values;
        delete newChanges.value;
      }
      newChanges.measurement_id = id.replace("measurement.", "");
    }

    if (id.startsWith('identifier_measurement.')) {
      newChanges.measurement_id = id.replace("identifier_measurement.", "");
    }

    const BIContext = { ...opts.BIContext, "data-id": id };
    measurementsContext.updateMeasurements(newChanges, { BIContext });
  }

  const onEndEditingDating = async (newChanges, opts = {}) => {
    setEditingFieldId(false);
    const update = { "dating.table": { "value": newChanges } };
    onEndEditingBIEvent("dating.table", newChanges, opts.bi ?? {});
    const updateReportOk = await updateReport(examId, update);
    loadMeasurementsReportData();
    return updateReportOk;
  };

  const onEndEditingBIEvent = (id, changes, BIContext) => {
    if (Array.isArray(changes)) {
      changes = changes
        .filter((change) => !isNullOrUndefined(change))
        .map((change, fetus) => ({ ...change, fetus, examination_fetus_id: fetuses[fetus]?.id }))
        .forEach((changes) => {
          ResourceApi.createBIEvent({
            ...BIContext,
            changes,
            "data-id": id,
            event_type: "report_field_edited",
          })
        });
    } else {
      ResourceApi.createBIEvent({
        ...BIContext,
        "data-id": id,
        changes,
        event_type: "report_field_edited",
      });
    }
  }

  const setAssignedGa = async (assignedMethodId, assignedExamId, assignedFetus) => {
    try {
      await ResourceApi.updateAssignedGa(examId, assignedExamId, assignedMethodId, assignedFetus);
      await loadDynamicReportData();
      await loadMeasurementsReportData();
      await examinationContext.refreshDating()
      return true;
    }
    catch (error) {
      notificationContext.showNotification(<><Icon name="warning" />{" "}{__("report.unableToUpdate")}</>, 5000);
      return false;
    }
  };

  const revertAssignedGa = async () => {
    try {
      await ResourceApi.revertAssignedGaToPrevExam(examId);
      await loadDynamicReportData();
      await loadMeasurementsReportData();
      await examinationContext.refreshDating()
    }
    catch (error) {
      notificationContext.showNotification(<><Icon name="warning" />{" "}{__("report.unableToUpdate")}</>, 5000);
    }
  };

  const getSlugFromLabel = (label) => label?.toLowerCase().replace(/[\W_]+/g, "_");

  const getDataById = (id, fallback = null) => {
    if (!id) return false;
    let output = { value: fallback ?? id.split('.').reduce((p, c) => p?.[c], reportData) };
    if (reportData.report?.user_edits?.[id]) {
      output = { ...output, ...reportData.report?.user_edits?.[id] };
    }
    return output;
  };

  const medicalHistoryOptions = (id, value) => {
    const item = examinationContext.medicalHistoryItems[id]
    if (!item)
      return {}

    const itemFormatFromMHType = (type) => {
      switch (type) {
        case "multiselect": return "multiple";
        case "buttongroup":
        case "text":
        case "select":
          return "string";
        case "date":
          return "date";
        case "number":
          return "number";
        default:
          return "string";
      }
    }

    const getOptionLabel = (item, value) => {
      if (!item.options?.length) return value;
      if (Array.isArray(value)) return value.map(value => item.options.find(option => option.value === value)?.label[currentLanguage]);
      return item.options.find(option => option.value === value)?.label[currentLanguage];
    }

    return {
      label: getOptionLabel(item, value ?? item.default),
      fieldLabel: item.label[currentLanguage],
      tree: convertMedicalHistoryOptionsToTree(item.options),
      type: item.type,
      format: itemFormatFromMHType(item.type),
    }
  }

  const getPlaceholderWithProps = (props, fieldType = "") => {
    const id = `${props.custom === "true" ? 'custom.' : ''}${props.data}`;
    const isArray = Array.isArray(placeholders?.[id]);
    const placeholder = isArray ? placeholders?.[id] : [placeholders?.[id]];

    const user_edits = reportData?.report?.user_edits?.[id] || {};
    const apply_user_edits = (props.data !== "fetus.sex" || placeholders["fetus.sex_visibility"]?.value === "visible");

    if (props.data?.startsWith('medical-history.')) delete user_edits.value;
    if (props.data?.startsWith('patient.')) delete user_edits.value;
    if (Object.keys(patientView).indexOf(props.data) != -1) delete user_edits.value;
    if (props.data == "fetus.sex") delete user_edits.value;
    if (props.data == "placenta.position") delete user_edits.value;
    if (props.data == "fetus.position") delete user_edits.value;

    const output = placeholder.map(p => ({
      ...(placeholders?.[`sonio.custom.${fieldType}`] || {}),
      ...(p || {}),
      ...(apply_user_edits ? user_edits : {}),
      data: props?.data,
      custom: props?.custom
    }));

    return (isArray || props?.data?.startsWith("measurement.")) ? output : output[0];
  };

  const getCarryForwardWithProps = (props, fieldType = "") => {
    const carryForwards = reportData.carry_forward || {};
    const id = `${props.custom === "true" ? 'custom.' : ''}${props.data}`;
    const isArray = Array.isArray(carryForwards?.[id]);
    const carryForward = isArray ? carryForwards?.[id] : [carryForwards?.[id]];

    const output = carryForward.map(p => ({
      ...(carryForwards?.[`sonio.custom.${fieldType}`] || {}),
      ...(p || {}),
      data: props?.data,
      custom: props?.custom
    }));

    return isArray ? output : output[0];
  };

  // If the field needs to be highlighted, then return the object (with icon), otherwise return false.
  // Example [{id: "examination.method", icon: 'flash'}]
  const getHighligthedWithProps = (props, fieldType = "") => {
    const slug = (props.custom === "true" ? "custom." : "") + props.data;
    return highlightedFields.find(e => e.slug === slug);
  }

  /* flash report field highlight */
  const isFlashReportField = useCallback((slug) => {
    // We support only custom, methods, indications and findings to be automated with flash reports now.
    return Object.keys(customPlaceholders).includes(slug) || slug.startsWith === "custom." || ["examination.method", "examination.indication", "examination.finding"].includes(slug);
  }, [customPlaceholders]);

  useEffect(() => {
    if (automationTemplateFieldsVisible) {
      setHighlightedFields(fields => {
        const allSlugs = [...Object.keys(placeholders), ...Object.keys(customPlaceholders)];
        return allSlugs.filter(slug => isFlashReportField(slug)).map(slug => ({
          slug: slug,
          icon: "flash",
          iconClass: "",
          source: 'flashReport'
        }))
      });
    }

    return () => {
      setHighlightedFields(fields => fields.filter(field => field.source !== 'flashReport'));
    }
  }, [automationTemplateFieldsVisible, isFlashReportField]);
  /* end of flash report field highlight */

  const getMeasurementObject = (_id, measurementId, measurement, fetusId) => {
    const measurementLabel = measurementsContext?.labels?.measurement?.[measurement.measurement]?.label?.[currentLanguage] || measurement.measurement;
    const siteLabel = measurementsContext?.labels?.body_structure?.[measurement.site]?.label?.[currentLanguage] || measurement.site;
    const lateralityLabel = measurementsContext?.labels?.laterality?.[measurement.laterality]?.label?.[currentLanguage] || measurement.laterality;

    let label = measurementLabel;
    if (siteLabel) label += `, ${siteLabel}`;
    if (lateralityLabel) label += ` (${lateralityLabel})`;

    const availableDerivations = (measurement?.all_values || []).map(val => ({ value: val.y, derivation: val.derivation }));

    let user_edit = measurementsContext.measurementData.measurement_edits?.[fetusId]?.[`${measurementId}`];
    let previousMeasurements = [];
    if (reportData?.previous_exams) {
      previousMeasurements = Object.values(reportData?.previous_exams)
        .map((examData) => {
          const measurements = examData?.measurements?.measurements;
          const selectedValue = (fetusId === "patient" ?
            measurements?.patient : measurements?.fetus?.[fetusId]
          )?.[measurementId]?.selected_value;

          return { examId, value: selectedValue?.y, xvalue: selectedValue?.x };
        })
        .filter(v => v.value && v.xvalue);
    }

    const percentiles = measurement.selected_value?.percentiles;
    return ({
      editedLabel: user_edit?.label,
      label,
      value: measurement.selected_value?.y, // Report edits are taken into account in derivations
      xvalue: measurement.selected_value?.x,
      units: measurementsContext?.labels?.measurement?.[measurement.measurement]?.units,
      comment: user_edit?.comment,
      visible: user_edit?.visible ?? null,
      visible_graph: user_edit?.visible_graph ?? null,
      curve_slug: measurement?.selected_value?.curve,
      percentile: measurement.selected_value?.sonio_percentile,
      zscore: measurement.selected_value?.sonio_zscore,
      reference_value: measurement.selected_value?.reference_value,
      estimation: measurement.selected_value?.estimation || false,
      derivation: measurement.selected_value?.derivation || false,
      availableDerivations,
      format: "number",
      previousMeasurements,
      percentiles,
      availableCurveSlugs: Object.keys(percentiles || {})
    });
  };

  useEffect(() => refreshPlaceholders(),
    [
      reportDataIncr,
      dynamicDropdowns,
      JSON.stringify(examinationContext.fetusSexVisibility),
      JSON.stringify(examinationContext.instances),
      JSON.stringify(examinationContext.examination.medical_history),
      JSON.stringify(examinationContext.examination.fetuses),
      JSON.stringify(examinationContext.patient),
      JSON.stringify(examinationContext.examinationInstanceViews),
      JSON.stringify(examinationContext.medicalHistoryItems),
      JSON.stringify(examinationContext.examination.events),
      examinationSite?.logo_base64,
      mandatoryChecklistSlugs,
      JSON.stringify(autogeneratedChecklistComments),
      examinationContext.debugTemplate,
    ]
  );

  /* Load static data */
  useEffect(() => {
    if (examinationFrozen)
      return
    if (!examinationContext.examination.id || !examinationContext.examination.preset_id)
      return
    dispatchNewReportData({ event: "reset" });
    loadStaticReportOptions();
  },
    [
      examinationContext.examination.id,
      examinationContext.examination.site_id,
      examinationContext.examination.preset_id,
      examinationFrozen
    ]);

  /* Load dynamic data */
  useEffect(() => {
    if (examinationFrozen)
      return
    if (!examinationContext.examination.id || !examinationContext.examination.preset_id)
      return
    if (!examinationContext.episode)
      return

    loadDynamicReportData().then(() => loadMeasurementsReportData())
  }, [
    examinationFrozen,
    JSON.stringify({
      ...examinationContext.examination,
      patient: {
        ...(examinationContext.examination?.patient || {}),
        /* Ignore modifications on those fields in the patient */
        medical_history: []
      },
      /* Ignore modifications on those fields */
      updated_at: '',
      events: [],
      medical_history: [],
      procedures_codes: [],
      diagnoses_codes: [],
    })
  ]);

  /* Load measurements */
  useEffect(() => {
    if (examinationFrozen)
      return
    setReportDataMeasurements(measurementsContext.measurementData);
  }, [measurementsContext.measurementData, examinationFrozen]);

  useEffect(() => {
    if (!examinationFrozen)
      return
    loadFrozenReportData();
  }, [examinationFrozen, examId])

  const additionalLoading = {
    examination: !!examinationContext.examination?.id
  }

  const applyAutomationTemplate = (slug) => {
    if (examinationContext.examination?.preset_id && slug) {
      ResourceApi.getAutomationTemplate(examinationContext.examination.preset_id, slug).then(resp => {
        evaluateAutomationTemplate(resp.data.data.template, { type: "automation_template", slug: resp.data.data.slug });
      })
    }
  }

  const evaluateAutomationTemplate = async (templateStr, source) => {
    // At the end of the template add the assigns_to_json - to convert all assigned items to JSON structure.
    let template = templateStr + `\n{% assigns_to_json %}`;

    // Prepare the input data for the template.
    // Note: we replace all dots in the keys with double underscores since LiquidJS does not support dots in keys.
    const backupReport = structuredClone(reportData);
    let data = replaceAllKeys({
      user_edits: backupReport.report.user_edits,
      measurements: backupReport.measurements
    }, ".", "__");

    let output = evalTemplate(template, data);
    let newUserEdits = replaceAllKeys(JSON.parse(output), "__", ".");

    // We only support keys starting with "custom." now (for security)
    // Also format the value to an object, and assume a single fetus now
    let processedNewUserEdits = {};
    for (const [key, value] of Object.entries(newUserEdits)) {
      if (key.startsWith("custom.")) {
        // If the value is a string we assume single fetus 
        if (typeof value === "string") {
          processedNewUserEdits[key] = {
            value: ["", value],
            source: source
          };
        }
        else {
          processedNewUserEdits[key] = {
            value: value,
            source: source
          };
        }
      }

      if (key === "examination.method") {
        let valueList = value.split(";").map(v => v.trim());
        processedNewUserEdits[key] = { value: await buildUserEditKeyForMethod(valueList), source: source };
      }

      if (key === "examination.indication" || key === "examination.finding") {
        // Currently in the template we define each item on one line.
        // They need to be merged into one object. 
        let allValues = Object.values(value).reduce((acc, item) => ({ ...acc, ...item }), {});
        processedNewUserEdits[key] = { value: allValues, source: source };
      }

      // Special keys 
      if (key === "fetalAnatomy") {
        // Build a map of slug -> checklistItem 
        let slugCIMap = backupReport.checklist_items.reduce((acc, item) => {
          acc[item.slug] = item;
          return acc;
        }, {})

        // For each status, update the checklist items
        Object.entries(value).forEach(([status, values]) => {
          // Repeat this for each valid fetus
          fetuses.forEach(examFetus => {
            let fetusId = examFetus.id;
            if (fetusId) {
              let checklistItems = values.map(v => ({ ...slugCIMap[v], examination_fetus_id: fetusId }));
              onEndEditingChecklist(status, checklistItems);
            }
          });
        });
      }
    }

    let changes = { ...backupReport.report.user_edits, ...processedNewUserEdits };

    dispatchNewReportData({ event: "user_edits", data: changes || {} });
    updateReport(examinationContext.examination.id, changes, backupReport);
  }

  // Builds the user_edit object for storing methods.
  const buildUserEditKeyForMethod = async (keys) => {
    let methodKeys = keys.map(key => `method.${key}`);

    if (isFeatureFlagEnabled("sonio.report_dropdown_options")) {
      const dynamicDropdownId = dynamicDropdowns["examination.method"]?.id;

      // Fetch all the included methods
      let methodsResponse = await Promise.all(methodKeys.map(key => ResourceApi.getDynamicDropdownOption(examinationContext.examination.id, dynamicDropdownId, key)));

      return methodsResponse.reduce((acc, resp, idx) => {
        let method = resp?.data;

        if (method && method.selectable) {
          acc[method.slug] = { label: method.label, description: method.description, value: true, order: idx };
          return acc;
        }

        return acc;
      }, {});
    } else {
      // Build a lookup of all method ID -> method object
      const allMethods = flattenMethodNestedCollections(reportData?.nested_collections?.method?.full_tree || []).reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      // For each method key, build the user_edit object
      return methodKeys.reduce((acc, key, idx) => {
        let method = allMethods[key];

        if (method && method.selectable) {
          acc[key] = { label: method.label, description: method.description, value: true, order: idx };
          return acc
        }
      }, {});
    }
  }

  const flattenMethodNestedCollections = (tree) => {
    if (!tree) return [];

    return tree.reduce((acc, item) => {
      acc.push({ id: item.id, selectable: item.selectable, label: item.label, description: item.description });

      if (item.tree.length > 0) {
        acc.push(...flattenMethodNestedCollections(item.tree));
      }

      return acc;
    }, []);
  }

  const generateAutomationTemplate = async (setTemplateCallback = null) => {
    let template = Object.keys(reportData.report.user_edits).filter((key) => key.startsWith("custom.")).map((key) => {
      return '{% assign ' + key.replaceAll(".", "__") + ' = "' + reportData.report.user_edits[key].value[1] + '" %}';
    }).join("\n");

    if (setTemplateCallback) {
      setTemplateCallback(template);
    }

    return template;
  }

  const loadDynamicDropdownFullTree = async (slug) => {
    const dropdownId = dynamicDropdowns[slug]?.id;
    const shouldLoad = !dynamicDropdowns[slug]?.loaded && !dynamicDropdowns[slug]?.loading;
    const hasSubNodes = dynamicDropdowns[slug]?.tree?.some(node => !node.selectable);

    if (dropdownId && shouldLoad && hasSubNodes) {
      setDynamicDropdowns(dropdowns => ({
        ...dropdowns, [slug]: {
          ...dropdowns[slug],
          loading: true,
        }
      }))

      ResourceApi.getDynamicDropdownFullTree(examinationContext.examination.id, dropdownId).then(response => {
        if (response.status === 200) {
          setDynamicDropdowns(dropdowns => ({
            ...dropdowns, [slug]: {
              ...response.data,
              loaded: true,
              loading: false,
            }
          }));
        } else {
          setDynamicDropdowns(dropdowns => ({
            ...dropdowns, [slug]: {
              ...dropdowns[slug],
              loaded: false,
              loading: false,
            }
          }));
        }
      });
    }
  };

  // TODO put these info inside debug panel
  window.placeholders = placeholders;
  window.reportData = reportData;
  window.reportVersion = "1.0.0"

  return (
    <XMLTemplateContext.Provider
      value={{
        loaded: Object.values({ ...loadingStatus, ...additionalLoading } || {}).every(s => s),
        BIContext,
        checkCondition,
        reportData,
        setReportData,
        dispatchNewReportData,
        placeholders,
        setPlaceholders,
        loadStaticReportOptions,
        customPlaceholders,
        setCustomPlaceholders,
        loadDynamicReportData,
        loadMeasurementsReportData,
        getPlaceholderWithProps,
        getCarryForwardWithProps,
        highlightedFields,
        setHighlightedFields,
        getHighligthedWithProps,
        loadDynamicDropdownFullTree,
        applyChanges,
        onEndEditing,
        onEndEditingDynamicDropdown,
        onEndEditingChecklist,
        onEndEditingDating,
        editingFieldId,
        startEditingField,
        setAssignedGa,
        revertAssignedGa,
        updateEpisode,
        applyAutomationTemplate,
        evaluateAutomationTemplate,
        generateAutomationTemplate,
        automationTemplateFieldsVisible,
        setAutomationTemplateFieldsVisible,
        componentChecklistAssoc,
        addComponentChecklistAssoc,
        removeComponentChecklistAssoc,
        updateAutogeneratedChecklistComments,
        reportDataOptions,
        apiVersion: "1.0",
      }}
    >
      {children}
    </XMLTemplateContext.Provider >
  );
});


export default XMLTemplateContextProvider;
