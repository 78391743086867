import { useContext, useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";

/* Contextes */
import { ExaminationContext } from "../../context-providers/Examination";
import { WindowContext } from "../../context-providers/Window";
import useAuth from "../../context-providers/Auth";

/* atoms */
import Icon from "../../atoms/Icon/Icon";
import Button from "../../atoms/Button/Button";

/* Services */
import ResourceApi from "../../services/resource";

/* CSS */
import "./index.css";
import ArrangeOnTop from "../../atoms/ArrangeOnTop/ArrangeOnTop";
import NumericInput from "../../atoms/NumericInput/NumericInput";

const ANNOTATION_COLOR = "#ffff00";
const ANNOTATION_FONT_SIZE = 36;
const CAPTION_FONT_SIZE = 20;
const CAPTION_LINE_HEIGHT = 25;
const CAPTION_WIDTH = 300;
const CAPTION_PADDING = 10;
const CAPTION_COLOR = "#ffff00";
const CAPTION_BACKGROUND_COLOR = "#404040";
const CLICK_TOLERANCE = 10;


const getThumbnailDataURL = (canvas) => {
  const dataURL = canvas?.toDataURL("image/jpeg", 1.0);
  return dataURL;
}

const uncacheThumbnail = (dicomInstanceId) => {
  /* This hack is to reload the thumbnail in the browser cache ... */

  const iframe = document.createElement("iframe");
  document.body.append(iframe);
  const img = iframe.contentWindow.document.createElement("img");
  img.addEventListener(
    "load",
    () => {
      /* We can now reload the iframe */
      iframe.addEventListener(
        "load",
        () => {
          /* Now we can delete everything */
          iframe.remove()
        }
      )
      iframe.contentWindow.location.reload(true);
      return
    },
    false,
  );
  img.src = `/api/v2/dicom-instance/${dicomInstanceId}/thumbnail`;
}

const extractMeasurement = (measurement, canvasToLocalmm) => {
  switch (measurement.type) {
    case "ellipse":
      const ellipseMiddle = segmentMiddle(measurement.axis);
      const axisXDistance = Math.sqrt(
        squareDistance(
          {
            x: canvasToLocalmm.x(measurement.axis.from.x),
            y: canvasToLocalmm.y(measurement.axis.from.y),
          },
          {
            x: canvasToLocalmm.x(measurement.axis.to.x),
            y: canvasToLocalmm.y(measurement.axis.to.y),
          }
        )
      );

      const axisYDistance = 2 * Math.sqrt(
        squareDistance(
          {
            x: canvasToLocalmm.x(ellipseMiddle.x),
            y: canvasToLocalmm.y(ellipseMiddle.y),
          },
          {
            x: canvasToLocalmm.x(measurement.projectedPosition.x),
            y: canvasToLocalmm.y(measurement.projectedPosition.y),
          }
        )
      );

      const rx = axisXDistance / 2;
      const ry = axisYDistance / 2;
      const h = Math.pow((rx - ry), 2) / Math.pow((rx + ry), 2);

      return [
        { label: "circum.", value: ((Math.PI * (rx + ry)) * (1 + ((3 * h) / (10 + Math.sqrt(4 - (3 * h)))))).toFixed(2) + " mm" },
        { label: "axisX", value: Number(axisXDistance).toFixed(2) + " mm" },
        { label: "axisY", value: Number(axisYDistance).toFixed(2) + " mm" },
      ];

    case "line":
      return [
        { label: "distance", value: Math.sqrt(
          squareDistance(
            {
              x: canvasToLocalmm.x(measurement.from.x),
              y: canvasToLocalmm.y(measurement.from.y),
            },
            {
              x: canvasToLocalmm.x(measurement.to.x),
              y: canvasToLocalmm.y(measurement.to.y),
            }
          )
        ).toFixed(2) + " mm" }
      ];
    
    default:
      return [];
  }
}

const drawSVGShape = (shape, componentContext, id) => {
  switch (shape.type) {
    case "text":
      /*
       * from: {x, y},
       * text: ""
       */
      return <text
        key={id + shape.type}
        x={shape.from.x}
        y={shape.from.y}
        className={shape.className}
        fill={ANNOTATION_COLOR}
        fontSize={ANNOTATION_FONT_SIZE}
        fontFamily="sans-serif"
        alignmentBaseline="baseline"
        draggable="true"
        onMouseDown={(event) => startSVGMove({event, componentContext})}
        onDoubleClick={(event) => startSVGAnnotationEdit({event, componentContext})}
        >{shape.text}</text>;

    case "dashed-line":
      /*
       * from: {x, y},
       * to: {x, y}
       */
      return <line key={id + shape.type} className="dashed" x1={shape.from.x} y1={shape.from.y} x2={shape.to.x} y2={shape.to.y} />;

    case "line":
    case "draggable-line":
      return <line key={id + shape.type} x1={shape.from.x} y1={shape.from.y} x2={shape.to.x} y2={shape.to.y} className={shape.type === "draggable-line" ? "edit-move" : ""} onMouseDown={(event) => shape.type === "draggable-line" ? startSVGMove({event, componentContext}) : false} />;

    case "circle":
      /*
       * from: {x, y},
       * radius: r,
       */

      return <circle
        key={id + "circle"}
        cx={shape.from.x}
        cy={shape.from.y}
        r={shape.radius}
        className={shape.editAttribute ? 'edit-handle' : ''}
        onMouseDown={(event) => shape.editAttribute ? startSVGModification({event, attribute: shape.editAttribute, componentContext}) : false}
      />;

    case "ellipse":
    case "draggable-ellipse":
      /*
       * axis: {
       *  from: {x, y},
       *  to: {x, y}
       * },
       * projectedPosition: {x, y}
       */
      const [ellipse_from, ellipse_to, ellipse_projectedPosition] = [
        { x: shape.axis.from.x, y: shape.axis.from.y },
        { x: shape.axis.to.x, y: shape.axis.to.y },
        { x: shape.projectedPosition.x, y: shape.projectedPosition.y },
      ];

      const ellipse_center = segmentMiddle({ from: ellipse_from, to: ellipse_to })
      const [axis_size, ellipse_angle, ellipse_orthogonalSize] = [
        Math.sqrt(squareDistance(ellipse_from, ellipse_to)),
        Math.atan2((ellipse_to.y - ellipse_from.y), (ellipse_to.x - ellipse_from.x)) * (180 / Math.PI),
        Math.sqrt(squareDistance(ellipse_center, ellipse_projectedPosition))
      ];

      return <ellipse key={id + shape.type} className={shape.type === "draggable-ellipse" ? "edit-move" : ""} onMouseDown={(event) => shape.type === "draggable-ellipse" ? startSVGMove({event, componentContext}) : false} cx={ellipse_center.x} cy={ellipse_center.y} rx={axis_size / 2} ry={ellipse_orthogonalSize} transform={`rotate(${ellipse_angle})`} transform-origin={ellipse_center.x + " " + ellipse_center.y} />;

    case "patch":
      const xr = Math.min(shape.from.x, shape.to.x);
      const yr = Math.min(shape.from.y, shape.to.y);
      const wr = Math.abs(shape.to.x - shape.from.x);
      const hr = Math.abs(shape.to.y - shape.from.y);
      return <rect key={id + shape.type} className="patch" x={xr} y={yr} width={wr} height={hr} onMouseDown={(event) => startSVGMove({event, componentContext})} />;

    default:
      return;
  }
}

const drawSVGCaption = (measurementsForCaption, componentContext) => {
  const measurements = measurementsForCaption.map(measurement => ({
    ...measurement,
    caption: extractMeasurement(measurement, componentContext.canvasToLocalmm) || [],
  }));
  const numberOfLines = measurements.map(m => m.caption).flat().length;
  
  const positionIsAuto = componentContext.caption.position === "auto" || !componentContext.caption.x || !componentContext.caption.y;
  const captionWidth = CAPTION_WIDTH - 70 + CAPTION_PADDING * 2;
  const captionHeight = (numberOfLines * CAPTION_LINE_HEIGHT) + (CAPTION_PADDING * 2);
  const captionX = positionIsAuto ? 10 : (componentContext.caption?.x || 10);
  const captionY = positionIsAuto ? componentContext.img?.height - captionHeight - 10 : (componentContext.caption?.y || 10);

  if (positionIsAuto && (!componentContext.caption.x || !componentContext.caption.y)) {
    // normalize position, to avoid drag and drop breaks
    componentContext.setCaption({
      position: "auto",
      x: captionX,
      y: captionY,
    })
  }

  return <g className="caption" transform={`translate(${captionX} ${captionY})`} onMouseDown={(event) => startSVGMove({event, componentContext})}>
    <rect className="background" x="0" y="0" width={captionWidth} height={captionHeight} fill={CAPTION_BACKGROUND_COLOR} />
    <g>
      <text x={CAPTION_PADDING} y={CAPTION_PADDING} fill={CAPTION_COLOR} fontSize={CAPTION_FONT_SIZE} fontFamily="sans-serif">
      {
      measurements
        .map((measurement) => (
          measurement.caption.map((caption, index) => (
            <tspan key={measurement.id + "_" + index} x={CAPTION_PADDING} dy={CAPTION_LINE_HEIGHT}>
              <tspan>{measurement.type} {caption.label}</tspan>
              <tspan x={CAPTION_PADDING + CAPTION_WIDTH - 70} textAnchor="end">{caption.value}</tspan>
              <line key={measurement.id + "_line_" + index} x1={CAPTION_PADDING} y1={30} x2={CAPTION_PADDING + CAPTION_WIDTH} y2={30} stroke={CAPTION_COLOR} strokeWidth="3" />
            </tspan>
          ))
        ))
      }
      </text>
    </g>
  </g>;
}

const drawShape = (shape, canvas, style, previewCanvasRatio) => {
  if (shape.editAttribute) return; // avoid edit handles

  const ctx = canvas.getContext("2d");
  ctx.save();
  applyStyle(ctx, style);
  const drawResult = doDrawShape(shape, ctx, previewCanvasRatio);
  ctx.restore();
  return drawResult;
}

const applyStyle = (ctx, style) => {
  return Object.entries(style).forEach(([key, value]) => {
    ctx[key] = value;
  })
}

const doDrawShape = (shape, ctx, previewCanvasRatio) => {
  switch (shape.type) {
    case "text":
      /*
       * from: {x, y},
       * text: ""
       */
      ctx.font = `${ANNOTATION_FONT_SIZE}px sans-serif`;
      ctx.fillStyle = ANNOTATION_COLOR;
      ctx.textBaseline = "bottom";
      ctx.fillText(shape.text, shape.from.x, shape.from.y);

      return;

    case "dashed-line":
      /*
       * from: {x, y},
       * to: {x, y}
       */
      ctx.beginPath();
      ctx.lineWidth = 2 * previewCanvasRatio.x;
      ctx.setLineDash([ctx.lineWidth, ctx.lineWidth * 2]);
      ctx.moveTo(shape.from.x, shape.from.y);
      ctx.lineTo(shape.to.x, shape.to.y);

      ctx.stroke();

      return;

    case "line":
      /*
       * from: {x, y},
       * to: {x, y}
       */
      ctx.beginPath();
      ctx.lineWidth = 2;
      ctx.moveTo(shape.from.x, shape.from.y);
      ctx.lineTo(shape.to.x, shape.to.y);

      ctx.stroke();

      return;

    case "circle":
      /*
       * from: {x, y},
       * radius: r,
       */
      ctx.beginPath();
      ctx.ellipse(shape.from.x, shape.from.y, shape.radius, shape.radius, 0, 0, 2 * Math.PI);
      ctx.stroke();
      return

    case "ellipse":
      /*
       * axis: {
       *  from: {x, y},
       *  to: {x, y}
       * },
       * projectedPosition
       */
      ctx.beginPath();
      const [ellipse_from, ellipse_to, ellipse_projectedPosition] = [
        { x: shape.axis.from.x, y: shape.axis.from.y },
        { x: shape.axis.to.x, y: shape.axis.to.y },
        { x: shape.projectedPosition.x, y: shape.projectedPosition.y },
      ]
      const ellipse_center = segmentMiddle({ from: ellipse_from, to: ellipse_to })
      const [axis_size, ellipse_angle, ellipse_orthogonalSize] = [
        Math.sqrt(squareDistance(ellipse_from, ellipse_to)),
        Math.atan((ellipse_from.y - ellipse_to.y) / (ellipse_from.x - ellipse_to.x)),
        Math.sqrt(squareDistance(ellipse_center, ellipse_projectedPosition))
      ]

      ctx.ellipse(ellipse_center.x, ellipse_center.y, axis_size / 2, ellipse_orthogonalSize, ellipse_angle, 0, 2 * Math.PI);
      ctx.stroke();
      return;

    case "patch":
      /*
       * from: {x, y},
       * to: {x, y}
       */
      ctx.beginPath();
      const xr = Math.min(shape.from.x, shape.to.x);
      const yr = Math.min(shape.from.y, shape.to.y);
      const wr = Math.abs(shape.to.x - shape.from.x);
      const hr = Math.abs(shape.to.y - shape.from.y);
      ctx.rect(xr, yr, wr, hr);
      ctx.fill();
      return;

    default:
      return;
  }
}

const drawCaption = (canvas, componentContext, previewCanvasRatio) => {
  const ctx = canvas.getContext("2d");

  const measurements = Object.entries(componentContext.measurements).map(([id, measurement]) => ([
    ...(extractMeasurement(measurement, componentContext.canvasToLocalmm) || []).map(m => ({
      id,
      ...measurement,
      ...m,
    }))
  ])).flat();
  const numberOfLines = measurements.length;
  
  const positionIsAuto = componentContext.caption.position === "auto" || !componentContext.caption.x || !componentContext.caption.y;
  const captionWidth = CAPTION_WIDTH - 70 + CAPTION_PADDING * 2;
  const captionHeight = (numberOfLines * CAPTION_LINE_HEIGHT) + (CAPTION_PADDING * 2);
  const captionX = positionIsAuto ? 10 : (componentContext.caption?.x || 10);
  const captionY = positionIsAuto ? componentContext.img?.height - captionHeight - 10 : (componentContext.caption?.y || 10);

  ctx.beginPath();
  ctx.lineWidth = 2;
  ctx.fillStyle = CAPTION_BACKGROUND_COLOR;
  ctx.rect(captionX, captionY, captionWidth, captionHeight);
  ctx.fill();

  ctx.font = `${CAPTION_FONT_SIZE}px sans-serif`;
  ctx.fillStyle = CAPTION_COLOR;
  ctx.textBaseline = "top";
  measurements.forEach((measurement, index) => {
    const yPosition = captionY + (CAPTION_LINE_HEIGHT * index) + CAPTION_PADDING;
    ctx.textAlign = "left";
    ctx.fillText(measurement.type + " " + measurement.label, captionX + CAPTION_PADDING, yPosition, captionWidth - 70);
    ctx.textAlign = "right";
    ctx.fillText(measurement.value, captionX + captionWidth - CAPTION_PADDING, yPosition);
  });
}


/* Transform a measurement to a shape that can be drawn on the canvas */
const measurementToShape = (measurement, scope) => {
  switch (measurement.type) {
    case "calibration":
      return [
        {
          type: "line",
          from: measurement.from,
          to: measurement.to,
        },
        ...editablePointDrawing(measurement.from, "from"),
        ...editablePointDrawing(measurement.to, "to"),
      ];
    case "ellipse":
      return ellipseDrawing(measurement.axis, measurement.projectedPosition);
    case "line":
      return [
        {
          type: "line",
          from: measurement.from,
          to: measurement.to,
        },
        {
          type: "draggable-line",
          from: measurement.from,
          to: measurement.to,
        },
        ...editablePointDrawing(measurement.from, "from"),
        ...editablePointDrawing(measurement.to, "to"),
      ];
    case "patch":
      return [
        {
          type: "patch",
          from: measurement.from,
          to: measurement.to,
        },
        ...(scope === "svg" ? [
          ...editablePointDrawing(measurement.from, "from"),
          ...editablePointDrawing(measurement.to, "to"),
        ] : [])
      ];
    case "text":
      return [{
        type: "text",
        from: measurement.from,
        text: measurement.text,
      }];
    default:
      return []
  }
}

/* Return the power 2 of the distance (using the square method) */
const squareDistance = (from, to) => {
  return Math.pow(from.x - to.x, 2) + Math.pow(from.y - to.y, 2)
}

/* Return the middle of a segment */
const segmentMiddle = (segment) => {
  return {
    x: (segment.from.x + segment.to.x) / 2,
    y: (segment.from.y + segment.to.y) / 2,
  }
}

/* get the position of the projected point in the median of a segment at a given distance */
const projectionOnMedianByDistance = (distance, segment) => {
  const middle = segmentMiddle(segment);
  const p = { x: segment.from.x - segment.to.x, y: segment.from.y - segment.to.y };
  let n = { x: -p.y, y: p.x };
  const norm_length = Math.sqrt((n.x * n.x) + (n.y * n.y));
  n.x /= norm_length;
  n.y /= norm_length;
  return { x: middle.x + (distance * n.x), y: middle.y + (distance * n.y) };
}

/* Project the current position on the line defined by the segment */
const projectionOnMedian = (currentPosition, segment) => {
  const middle = segmentMiddle(segment);
  const colinearVector = {
    x: segment.from.x - segment.to.x,
    y: segment.from.y - segment.to.y
  }

  const normalVector = {
    x: colinearVector.y,
    y: - colinearVector.x,
  }

  /* Vector from the middle of the ellipse to the currentPosition of the cursor */
  const currentVector = {
    x: currentPosition.x - middle.x,
    y: currentPosition.y - middle.y,
  }

  /* We want to express the current position in the base of colinearVector and normalVector.
   * So we have the following equation system to solve 
   *  [ currentVector.x = a * normalVector.x + b * colinearVector.x
   *  [ currentVector.y = a * normalVector.y + b * colinearVector.y
   *
   * Then having the projection of the vector would simply be:
   * [ projection.x = a * normalVector.x
   * [ projection.y = a * normalVector.y
   *
   *  To find a we can express the upper equation system as:
   *  [ (currentVector.x + currentVector.y) = a * (normalVector.x + normalVector.y) + b * (colinearVector.x + colinearVector.y)
   *  [ (currentVector.x - currentVector.y) = a * (normalVector.x - normalVector.y) + b * (colinearVector.x - colinearVector.y)
   *
   *  Here we have 2 possible expression of b in function of a
   *    If colinearVector.x + colinearVector.y != 0
   *      b = ((currentVector.x + currentVector.y) - a * (normalVector.x + normalVector.y)) / (colinearVector.x + colinearVector.y)
   *    If colinearVector.x - colinearVector.y != 0
   *      b = ((currentVector.x - currentVector.y) - a * (normalVector.x - normalVector.y)) / (colinearVector.x + colinearVector.y)
   *
   *  And by replacing b in the upper system
   *    If colinearVector.x + colinearVector.y != 0
   *      currentVector.x = a * normalVector.x + (((currentVector.x + currentVector.y) - a * (normalVector.x + normalVector.y)) / (colinearVector.x + colinearVector.y)) * colinearVector.x
   *      currentVector.x - ((currentVector.x + currentVector.y) / (colinearVector.x + colinearVector.y)) * colinearVector.x = a * (normalVector.x - (normalVector.x + normalVector.y) / (colinearVector.x + colinearVector.y) * colinearVector.x)
   *      a = (currentVector.x - ((currentVector.x + currentVector.y) / (colinearVector.x + colinearVector.y)) * colinearVector.x) / (normalVector.x - (normalVector.x + normalVector.y) / (colinearVector.x + colinearVector.y) * colinearVector.x)
   *    If colinearVector.x - colinearVector.y != 0
   *      currentVector.x = a * normalVector.x + (((currentVector.x - currentVector.y) - a * (normalVector.x - normalVector.y)) / (colinearVector.x + colinearVector.y)) * colinearVector.x
   *      currentVector.x - ((currentVector.x - currentVector.y) / (colinearVector.x + colinearVector.y)) * colinearVector.x = a * normalVector.x + ((normalVector.x - normalVector.y) / (colinearVector.x + colinearVector.y)) * colinearVector.x
   *      a = (currentVector.x - ((currentVector.x - currentVector.y) / (colinearVector.x + colinearVector.y)) * colinearVector.x) / normalVector.x + ((normalVector.x - normalVector.y) / (colinearVector.x + colinearVector.y)) * colinearVector.x
   */

  if (colinearVector.x + colinearVector.y != 0) {
    const a = (currentVector.x - ((currentVector.x + currentVector.y) / (colinearVector.x + colinearVector.y)) * colinearVector.x) / (normalVector.x - (normalVector.x + normalVector.y) / (colinearVector.x + colinearVector.y) * colinearVector.x)

    return {
      x: a * normalVector.x + middle.x,
      y: a * normalVector.y + middle.y
    }
  } else {
    /* NB: colinearVector.x + colinearVector.y == 0 && colinearVector.x - colinearVector.y => colinearVector.x == 0 && colinearVector.y == 0 */
    const a = (currentVector.x - ((currentVector.x - currentVector.y) / (colinearVector.x + colinearVector.y)) * colinearVector.x) / normalVector.x + ((normalVector.x - normalVector.y) / (colinearVector.x + colinearVector.y)) * colinearVector.x

    return {
      x: a * normalVector.x + middle.x,
      y: a * normalVector.y + middle.y
    }
  }
}

const ellipseDrawing = (initialVector, projectedPosition) => {
  return [
    {
      type: "dashed-line",
      ...initialVector
    },
    {
      type: "draggable-line",
      ...initialVector,
    },
    {
      type: "dashed-line",
      from: segmentMiddle(initialVector),
      to: projectedPosition,
    },
    {
      type: "draggable-line",
      from: segmentMiddle(initialVector),
      to: projectedPosition,
    },
    {
      type: "ellipse",
      axis: { ...initialVector },
      projectedPosition
    },
    {
      type: "draggable-ellipse",
      axis: { ...initialVector },
      projectedPosition
    },
    ...editablePointDrawing(initialVector.from, "from"),
    ...editablePointDrawing(initialVector.to, "to"),
    ...editablePointDrawing(projectedPosition, "projectedPosition"),
  ];
}

const editablePointDrawing = (point, attribute, options = null) => {
  const renderToCanvas = options?.renderToCanvas ?? false;

  return [
    {
      type: "line",
      from: { x: point.x - 5, y: point.y },
      to: { x: point.x + 5, y: point.y },
    },
    {
      type: "line",
      from: { x: point.x, y: point.y - 5 },
      to: { x: point.x, y: point.y + 5 },
    },
    {
      type: "circle",
      from: point,
      radius: 12,
      editAttribute: attribute,
    },
  ];
}

const pointDrawing = (point) => {
  return [
    {
      type: "line",
      from: { x: point.x - 5, y: point.y },
      to: { x: point.x + 5, y: point.y },
    },
    {
      type: "line",
      from: { x: point.x, y: point.y - 5 },
      to: { x: point.x, y: point.y + 5 },
    },
  ];
}

const pointInArea = (point, area) => {
  const left = Math.min(area.from.x, area.to.x)
  const right = Math.max(area.from.x, area.to.x)
  const top = Math.min(area.from.y, area.to.y)
  const bottom = Math.max(area.from.y, area.to.y)

  return point.x >= left && point.x <= right && point.y >= top && point.y <= bottom
}

const areIntersecting = (rect, area) => {
  if (!rect.to) rect.to = rect.from;
  const rectLeft = Math.min(rect.from.x, rect.to.x)
  const rectRight = Math.max(rect.from.x, rect.to.x)
  const rectTop = Math.min(rect.from.y, rect.to.y)
  const rectBottom = Math.max(rect.from.y, rect.to.y)

  const areaLeft = Math.min(area.from.x, area.to.x)
  const areaRight = Math.max(area.from.x, area.to.x)
  const areaTop = Math.min(area.from.y, area.to.y)
  const areaBottom = Math.max(area.from.y, area.to.y)

  return !(rectLeft > areaRight || rectRight < areaLeft || rectTop > areaBottom || rectBottom < areaTop);
}

const isMeasurementInArea = (measurement, area) => {
  switch (measurement.type) {
    case "text":
      return areIntersecting(measurement, area);

    case "ellipse":
      const ellipseCenter = segmentMiddle(measurement.axis);
      const projectedPosition2 = {
        x: 2 * ellipseCenter.x - measurement.projectedPosition.x,
        y: 2 * ellipseCenter.y - measurement.projectedPosition.y,
      };
      return [
        measurement.axis.from,
        measurement.axis.to,
        measurement.projectedPosition,
        projectedPosition2,
      ].some((p) => pointInArea(p, area));

    case "line":
      return [
        measurement.from,
        measurement.to
      ].some((p) => pointInArea(p, area));
  }
}

/* Find elements of the inteface to be selected */
const findSelectedElements = (area, measurements) => {
  const results = Object.entries(measurements)
    .reverse()
    .filter(([_, measurement]) => isMeasurementInArea(measurement, area))

  return results.map(result => ({ type: "measurement", id: result[0] }));
}



/****************************/
/* Component implementation */
/****************************/

const WarningMessage = ({ short, full, cta }) => {
  const [display, setDisplay] = useState(true)
  if (!display)
    return null

  return (
    <div className="image-manipulation-warning">
      <div className="image-manipulation-warning-message">
        <div className="image-manipulation-warning_icon">
          <Icon name="attention" />
        </div>
        <div>
          <div className="image-manipulation-warning_short">{short}</div>
          {!!full && <div className="image-manipulation-warning_full">{full}</div>}
        </div>
        {cta && <div className="image-manipulation-warning_cta">{cta}</div>}
        <div className="image-manipulation-warning-close" onClick={() => setDisplay(false)}>
          <Icon name="close" />
        </div>
      </div>
    </div>
  )
}

const startSVGModification = ({event, attribute, componentContext}) => {
  event.stopPropagation();
  event.preventDefault();
  const findMeasurementId = (element) => element ? (element.dataset?.id || findMeasurementId(element.parentNode)) : false;
  const measurementId = findMeasurementId(event?.target);
  const measurement = componentContext.measurements[measurementId];

  if (!measurement) return null;

  componentContext.setSelectedElements([{type: "measurement", id: measurementId}]);

  const setMeasurement = (measurement) => componentContext.setMeasurements(measurements => ({ ...measurements, [measurementId]: measurement }));
  const saveMeasurement = (newMeasurement) => componentContext.addNewOperation({
    type: "edit-measurement",
    id: measurementId,
    previous: measurement,
    new: newMeasurement
  });

  switch (measurement.type) {
    case "line":
    case "calibration":
      (() => {
        event.target.style.pointerEvents = "none";
  
        const onMouseMoveHandler = {
          handleEvent: (e) => {
            e.preventDefault();
            e.stopPropagation();
            const currentPosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };
            setMeasurement({ ...measurement, [attribute]: currentPosition });
          }
        }
  
        const onMouseUpHandler = {
          handleEvent: (e) => {
            e.preventDefault();
            e.stopPropagation();
            const currentPosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };
            saveMeasurement({ ...measurement, [attribute]: currentPosition });
            event.target.style.pointerEvents = "";
            window.removeEventListener("mousemove", onMouseMoveHandler, false);
            window.removeEventListener("mouseup", onMouseUpHandler, false);
          }
        }
  
        window.addEventListener("mousemove", onMouseMoveHandler, false);
        window.addEventListener("mouseup", onMouseUpHandler, false);
      })();
      return;

    case "ellipse":
      (() => {
        event.target.style.pointerEvents = "none";
  
        const getUpdatedMeasurement = () => {
          const currentPosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };
          const newMeasurement = {
            ...measurement,
            axis: {
              from: attribute === "from" ? currentPosition : measurement.axis.from,
              to: attribute === "to" ? currentPosition : measurement.axis.to,
            }
          }
          newMeasurement.projectedPosition = projectionOnMedian(attribute === "projectedPosition" ? currentPosition : measurement.projectedPosition, newMeasurement.axis);
          return newMeasurement;
        }

        const onMouseMoveHandler = {
          handleEvent: (e) => {
            e.preventDefault();
            e.stopPropagation();
            setMeasurement(getUpdatedMeasurement());
          }
        }
  
        const onMouseUpHandler = {
          handleEvent: (e) => {
            e.preventDefault();
            e.stopPropagation();
            saveMeasurement(getUpdatedMeasurement());
            event.target.style.pointerEvents = "";
            window.removeEventListener("mousemove", onMouseMoveHandler, false);
            window.removeEventListener("mouseup", onMouseUpHandler, false);
          }
        }
  
        window.addEventListener("mousemove", onMouseMoveHandler, false);
        window.addEventListener("mouseup", onMouseUpHandler, false);
      })();
      return;

    case "patch":
      (() => {
        event.target.style.pointerEvents = "none";
  
        const onMouseMoveHandler = {
          handleEvent: (e) => {
            e.preventDefault();
            e.stopPropagation();
            const currentPosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };
            setMeasurement({ ...measurement, [attribute]: currentPosition });
          }
        }
  
        const onMouseUpHandler = {
          handleEvent: (e) => {
            e.preventDefault();
            e.stopPropagation();
            const currentPosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };
            saveMeasurement({ ...measurement, [attribute]: currentPosition });
            event.target.style.pointerEvents = "";
            window.removeEventListener("mousemove", onMouseMoveHandler, false);
            window.removeEventListener("mouseup", onMouseUpHandler, false);
          }
        }
  
        window.addEventListener("mousemove", onMouseMoveHandler, false);
        window.addEventListener("mouseup", onMouseUpHandler, false);
      })();
      return;
  
    default:
      console.error("Editing not implemented for measurement", measurement.type);
      return;
  }
}

const startSVGMove = ({event, componentContext}) => {
  event.stopPropagation();
  event.preventDefault();
  const findMeasurementId = (element) => element ? (element.dataset?.id || findMeasurementId(element.parentNode)) : false;
  const measurementId = findMeasurementId(event?.target);
  const measurement = measurementId === "caption" ? {...componentContext.caption, type: "caption"} : componentContext.measurements[measurementId];

  if (!measurement) return null

  measurementId !== "caption" && componentContext.setSelectedElements([{type: "measurement", id: measurementId}]);

  const setCaption = (caption) => componentContext.setCaption({...caption, position: "manual"});
  const setMeasurement = (measurement) => componentContext.setMeasurements(measurements => ({ ...measurements, [measurementId]: measurement }));
  const saveMeasurement = (newMeasurement) => componentContext.addNewOperation({
    type: "edit-measurement",
    id: measurementId,
    previous: measurement,
    new: newMeasurement
  });

  
  switch (measurement.type) {
    case "line":
      (() => {
        event.target.style.pointerEvents = "none";
        const initialMousePosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };
        const initialMeasurement = structuredClone(measurement);
  
        const updateMeasurement = () => {
          const delta = { x: initialMousePosition.x - mousePosition.canvasX, y: initialMousePosition.y - mousePosition.canvasY };
          return {
            ...measurement,
            from: {
              x: initialMeasurement.from.x - delta.x,
              y: initialMeasurement.from.y - delta.y,
            },
            to: {
              x: initialMeasurement.to.x - delta.x,
              y: initialMeasurement.to.y - delta.y,
            },
          }
        };
  
        const onMouseMoveHandler = {
          handleEvent: (e) => {
            e.preventDefault();
            e.stopPropagation();
            setMeasurement(updateMeasurement());
          }
        }
  
        const onMouseUpHandler = {
          handleEvent: (e) => {
            e.preventDefault();
            e.stopPropagation();
            saveMeasurement(updateMeasurement());
            event.target.style.pointerEvents = "";
            window.removeEventListener("mousemove", onMouseMoveHandler, false);
            window.removeEventListener("mouseup", onMouseUpHandler, false);
          }
        }
  
        window.addEventListener("mousemove", onMouseMoveHandler, false);
        window.addEventListener("mouseup", onMouseUpHandler, false);
      })();
      return;

    case "ellipse":
      (() => {
        event.target.style.pointerEvents = "none";
        const initialMousePosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };
        const initialMeasurement = structuredClone(measurement);
  
        const updateMeasurement = () => {
          const delta = { x: initialMousePosition.x - mousePosition.canvasX, y: initialMousePosition.y - mousePosition.canvasY };
          return {
            ...measurement,
            axis: {
              from: {
                x: initialMeasurement.axis.from.x - delta.x,
                y: initialMeasurement.axis.from.y - delta.y,
              },
              to: {
                x: initialMeasurement.axis.to.x - delta.x,
                y: initialMeasurement.axis.to.y - delta.y,
              },
            },
            projectedPosition: {
              x: initialMeasurement.projectedPosition.x - delta.x,
              y: initialMeasurement.projectedPosition.y - delta.y,
            },
          }
        };
  
        const onMouseMoveHandler = {
          handleEvent: (e) => {
            e.preventDefault();
            e.stopPropagation();
            setMeasurement(updateMeasurement());
          }
        }
  
        const onMouseUpHandler = {
          handleEvent: (e) => {
            e.preventDefault();
            e.stopPropagation();
            saveMeasurement(updateMeasurement());
            event.target.style.pointerEvents = "";
            window.removeEventListener("mousemove", onMouseMoveHandler, false);
            window.removeEventListener("mouseup", onMouseUpHandler, false);
          }
        }
  
        window.addEventListener("mousemove", onMouseMoveHandler, false);
        window.addEventListener("mouseup", onMouseUpHandler, false);
      })();
      return;

    case "patch":
      (() => {
        event.target.style.pointerEvents = "none";
        const initialMousePosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };
        const initialMeasurement = structuredClone(measurement);
  
        const updateMeasurement = () => {
          const delta = { x: initialMousePosition.x - mousePosition.canvasX, y: initialMousePosition.y - mousePosition.canvasY };
          return {
            ...measurement,
            from: {
              x: initialMeasurement.from.x - delta.x,
              y: initialMeasurement.from.y - delta.y,
            },
            to: {
              x: initialMeasurement.to.x - delta.x,
              y: initialMeasurement.to.y - delta.y,
            },
          }
        };
  
        const onMouseMoveHandler = {
          handleEvent: (e) => {
            e.preventDefault();
            e.stopPropagation();
            setMeasurement(updateMeasurement());
          }
        }
  
        const onMouseUpHandler = {
          handleEvent: (e) => {
            e.preventDefault();
            e.stopPropagation();
            saveMeasurement(updateMeasurement());
            event.target.style.pointerEvents = "";
            window.removeEventListener("mousemove", onMouseMoveHandler, false);
            window.removeEventListener("mouseup", onMouseUpHandler, false);
          }
        }
  
        window.addEventListener("mousemove", onMouseMoveHandler, false);
        window.addEventListener("mouseup", onMouseUpHandler, false);
      })();
      return;

    case "text":
      (() => {
        const initialMousePosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };
        const initialMeasurement = structuredClone(measurement);
        let moved = false;
        
        const updateMeasurement = () => {
          const delta = { x: initialMousePosition.x - mousePosition.canvasX, y: initialMousePosition.y - mousePosition.canvasY };
          return {
            ...measurement,
            from: {
              x: initialMeasurement.from.x - delta.x,
              y: initialMeasurement.from.y - delta.y,
            },
          }
        };
        
        const onMouseMoveHandler = {
          handleEvent: (e) => {
            event.target.style.pointerEvents = "none";
            e.preventDefault();
            e.stopPropagation();
            setMeasurement(updateMeasurement());
            moved = true;
          }
        }
        
        const onMouseUpHandler = {
          handleEvent: (e) => {
            if (moved) {
              e.preventDefault();
              e.stopPropagation();
              saveMeasurement(updateMeasurement());
              moved = false;
            }
            event.target.style.pointerEvents = "";
            window.removeEventListener("mousemove", onMouseMoveHandler, false);
            window.removeEventListener("mouseup", onMouseUpHandler, false);
          }
        }
  
        window.addEventListener("mousemove", onMouseMoveHandler, false);
        window.addEventListener("mouseup", onMouseUpHandler, false);
      })();
      return;

    case "caption":
      (() => {
        event.target.style.pointerEvents = "none";
        const initialMousePosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };
        const initialMeasurement = structuredClone(measurement);
  
        const updateCaption = () => {
          const delta = { x: initialMousePosition.x - mousePosition.canvasX, y: initialMousePosition.y - mousePosition.canvasY };
          return {
            x: parseInt(initialMeasurement.x - delta.x),
            y: parseInt(initialMeasurement.y - delta.y),
          }
        };
  
        const onMouseMoveHandler = {
          handleEvent: (e) => {
            e.preventDefault();
            e.stopPropagation();
            setCaption(updateCaption());
          }
        }
  
        const onMouseUpHandler = {
          handleEvent: (e) => {
            e.preventDefault();
            e.stopPropagation();
            event.target.style.pointerEvents = "";
            window.removeEventListener("mousemove", onMouseMoveHandler, false);
            window.removeEventListener("mouseup", onMouseUpHandler, false);
          }
        }
  
        window.addEventListener("mousemove", onMouseMoveHandler, false);
        window.addEventListener("mouseup", onMouseUpHandler, false);
      })();
      return;
 
    default:
      console.error("Editing not implemented for measurement", measurement.type);
      return;
  }
}

const startSVGAnnotationEdit = ({event, componentContext}) => {
  event.stopPropagation();
  event.preventDefault();
  const findMeasurementId = (element) => element ? (element.dataset?.id || findMeasurementId(element.parentNode)) : false;
  const measurementId = findMeasurementId(event?.target);

  if (!measurementId) return null

  onStartAnnotate(componentContext, measurementId);
}

let mousePosition = {};
let mouseWheel = {};

const onEditMouseDown = (e, componentContext) => {
  e.preventDefault();
  e.stopPropagation();

  const { measurements, setSelectionArea, setSelectedElements, mode } = componentContext;

  const currentPosition = { x: mousePosition?.canvasWrapperX, y: mousePosition?.canvasWrapperY, canvasX: mousePosition.canvasX, canvasY: mousePosition.canvasY };
  let updatedSelectionArea = {
    from: currentPosition,
    to: currentPosition
  };
  setSelectionArea(updatedSelectionArea);

  const mouseMoveHandler = () => {
    setSelectionArea(selectionArea => {
      const currentPosition = { x: mousePosition?.canvasWrapperX, y: mousePosition?.canvasWrapperY, canvasX: mousePosition.canvasX, canvasY: mousePosition.canvasY };
      updatedSelectionArea = { ...selectionArea, to: currentPosition };
      return updatedSelectionArea;
    }
    )
  };

  const mouseUpHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    window.removeEventListener("mouseup", mouseUpHandler, false);
    window.removeEventListener("mousemove", mouseMoveHandler, false);

    const tolerance = CLICK_TOLERANCE * mousePosition.canvasPixelRatio;

    const selectedElements = (updatedSelectionArea.from.x === updatedSelectionArea.to.x && updatedSelectionArea.from.y === updatedSelectionArea.to.y)
      ? findSelectedElements({
        from: { x: updatedSelectionArea.from.canvasX - tolerance, y: updatedSelectionArea.from.canvasY - tolerance },
        to: { x: updatedSelectionArea.from.canvasX + tolerance, y: updatedSelectionArea.from.canvasY + tolerance },
      }, measurements)
      : findSelectedElements({
        from: { x: updatedSelectionArea.from.canvasX, y: updatedSelectionArea.from.canvasY },
        to: { x: updatedSelectionArea.to.canvasX, y: updatedSelectionArea.to.canvasY },
      }, measurements);

    if (mode !== "calibrate") {
      setSelectedElements(selectedElements);
    }
    setSelectionArea(null);
    updatedSelectionArea = null;
  }

  window.addEventListener("mouseup", mouseUpHandler, false);
  window.addEventListener("mousemove", mouseMoveHandler, false);
};

const onAnnotateMouseDown = (e, componentContext) => {
  onStartDrawing(e, componentContext);
}

const onDrawingMouseDown = (e, componentContext) => {
  if (componentContext.currentlyDrawing) return;
  componentContext.setCurrentlyDrawing(true);
  onStartDrawing(e, componentContext)
    .finally(() => componentContext.setCurrentlyDrawing(false))
}

const onStartDrawingEllipse = (componentContext) => {
  return new Promise((resolve, reject) => {
    const { setMode, setCurrentDrawing, addNewMeasurement, setSelectedElements } = componentContext;

    const initialPosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };
    const initialVector = { from: initialPosition, to: initialPosition };
    let currentVector = { from: initialPosition, to: initialPosition };
    let currentDelta = 0;
    let drawing = "vector";

    setCurrentDrawing(pointDrawing(initialPosition))

    /* The orthogonal projection of the initialVector.to onto the initialVector median is the middle of the initial vector
     * This is a slight optimization to draw it faster
     */
    setCurrentDrawing(ellipseDrawing(initialVector, segmentMiddle(initialVector)));

    const mouseMoveEventListener = {
      handleEvent: (e) => {
        e.preventDefault();
        e.stopPropagation();
        const currentPosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };
        if (drawing === "vector") {
          currentVector = { from: initialPosition, to: currentPosition };
          setCurrentDrawing(ellipseDrawing(currentVector, projectionOnMedianByDistance(currentDelta, currentVector)));
        } else {
          setCurrentDrawing(ellipseDrawing(currentVector, projectionOnMedian(currentPosition, currentVector)));
        }
      }
    }

    const mouseUpEventListener = {
      handleEvent: (e) => {
        e.preventDefault();
        e.stopPropagation();
        const currentPosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };
        const tolerance = CLICK_TOLERANCE * mousePosition.canvasPixelRatio;

        if (
          Math.abs(currentPosition.x - initialPosition.x) > tolerance
          || Math.abs(currentPosition.y - initialPosition.y) > tolerance
        ) {
          if (drawing === "vector" && currentDelta === 0) {
            drawing = "median";
          } else {
            const newMeasurement = {
              type: "ellipse",
              axis: currentVector,
              projectedPosition: currentDelta ? projectionOnMedianByDistance(currentDelta, currentVector) : projectionOnMedian(currentPosition, currentVector)
            };
            setCurrentDrawing(null);
            setMode("edit");
            const uuid = addNewMeasurement(newMeasurement);
            setSelectedElements([{ type: "measurement", id: uuid }]);

            window.removeEventListener("mousemove", mouseMoveEventListener);
            window.removeEventListener("mouseup", mouseUpEventListener);
            window.removeEventListener("wheel", mouseWheelEventListener);
            resolve();
          }
        }
      }
    }

    const mouseWheelEventListener = {
      handleEvent: (e) => {
        e.preventDefault();
        e.stopPropagation();
        currentDelta += (mouseWheel.deltaY > 0 ? 1 : -1) * mousePosition.canvasPixelRatio;
        mouseMoveEventListener.handleEvent(e);
      }
    }

    window.addEventListener("mousemove", mouseMoveEventListener, false);
    window.addEventListener("mouseup", mouseUpEventListener, false);
    window.addEventListener("wheel", mouseWheelEventListener, false);
  })
}

const onStartDrawingCalibration = (componentContext) => {
  return new Promise((resolve, reject) => {
    const { setMode, setCurrentDrawing, addNewMeasurement, setSelectedElements } = componentContext;

    const initialPosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };
    setCurrentDrawing(pointDrawing(initialPosition))

    const mouseMoveEventListener = {
      handleEvent: (e) => {
        e.preventDefault();
        e.stopPropagation();
        const currentPosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };
        setCurrentDrawing(pointDrawing(initialPosition).concat(pointDrawing(currentPosition)).concat({
          type: "dashed-line",
          from: initialPosition,
          to: currentPosition,
        }));
      }
    }

    const mouseUpEventListener = {
      handleEvent: (e) => {
        e.preventDefault();
        e.stopPropagation();
        const currentPosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };
        const tolerance = CLICK_TOLERANCE * mousePosition.canvasPixelRatio;

        if (
          Math.abs(currentPosition.x - initialPosition.x) > tolerance
          || Math.abs(currentPosition.y - initialPosition.y) > tolerance
        ) {
          const newMeasurement = {
            type: "calibration",
            from: initialPosition,
            to: currentPosition,
            size: 10,
          };
          setCurrentDrawing(null);
          const uuid = addNewMeasurement(newMeasurement);
          setSelectedElements([{ type: "calibration", id: uuid }]);

          window.removeEventListener("mousemove", mouseMoveEventListener);
          window.removeEventListener("mouseup", mouseUpEventListener);
          resolve();
        }
      }
    }

    window.addEventListener("mousemove", mouseMoveEventListener, false);
    window.addEventListener("mouseup", mouseUpEventListener, false);
  })
}

const onStartDrawingLine = (componentContext) => {
  return new Promise((resolve, reject) => {
    const { setMode, setCurrentDrawing, addNewMeasurement, setSelectedElements } = componentContext;

    const initialPosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };

    setCurrentDrawing(pointDrawing(initialPosition))

    const mouseMoveEventListener = {
      handleEvent: (e) => {
        e.preventDefault();
        e.stopPropagation();
        const currentPosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };
        setCurrentDrawing(pointDrawing(initialPosition).concat(pointDrawing(currentPosition)).concat({
          type: "dashed-line",
          from: initialPosition,
          to: currentPosition,
        }));
      }
    }

    const mouseUpEventListener = {
      handleEvent: (e) => {
        e.preventDefault();
        e.stopPropagation();
        const currentPosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };
        const tolerance = CLICK_TOLERANCE * mousePosition.canvasPixelRatio;

        if (
          Math.abs(currentPosition.x - initialPosition.x) > tolerance
          || Math.abs(currentPosition.y - initialPosition.y) > tolerance
        ) {
          const newMeasurement = {
            type: "line",
            from: initialPosition,
            to: currentPosition,
          };
          setCurrentDrawing(null);
          setMode("edit");
          const uuid = addNewMeasurement(newMeasurement);
          setSelectedElements([{ type: "measurement", id: uuid }]);

          window.removeEventListener("mousemove", mouseMoveEventListener);
          window.removeEventListener("mouseup", mouseUpEventListener);
          resolve();
        }
      }
    }

    window.addEventListener("mousemove", mouseMoveEventListener, false);
    window.addEventListener("mouseup", mouseUpEventListener, false);
  })
}

const onStartDrawingPatch = (componentContext) => {
  return new Promise((resolve, reject) => {
    const { setMode, setCurrentDrawing, addNewMeasurement, setSelectedElements } = componentContext;

    const initialPosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };

    setCurrentDrawing(pointDrawing(initialPosition));

    const mouseMoveEventListener = {
      handleEvent: (e) => {
        e.preventDefault();
        e.stopPropagation();
        const currentPosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };
        setCurrentDrawing([{
          type: "patch",
          from: initialPosition,
          to: currentPosition,
        }]);
      }
    }

    const mouseUpEventListener = {
      handleEvent: (e) => {
        e.preventDefault();
        e.stopPropagation();
        const currentPosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };
        const uuid = addNewMeasurement({
          type: "patch",
          from: initialPosition,
          to: currentPosition,
        });
        setCurrentDrawing(null);
        setMode("edit");
        setSelectedElements([{ type: "measurement", id: uuid }]);

        window.removeEventListener("mousemove", mouseMoveEventListener);
        window.removeEventListener("mouseup", mouseUpEventListener);
        resolve();
      }
    }

    window.addEventListener("mousemove", mouseMoveEventListener, false);
    window.addEventListener("mouseup", mouseUpEventListener, false);
  })
}

const onStartAnnotate = (componentContext, measurementId = false) => {
  return new Promise((resolve, reject) => {
    const { setMode, measurements, canvasRef, editorRef, currentlyDrawing, setCurrentlyDrawing, setCurrentDrawing, addNewMeasurement, deleteMeasurement } = componentContext;

    const initialPosition = { x: mousePosition.canvasX, y: mousePosition.canvasY };
    const measurement = measurementId ? measurements[measurementId] : false;

    if (!currentlyDrawing) {
      setCurrentDrawing([{
        type: "text",
        from: measurement?.from ?? initialPosition,
        text: measurement?.text?.trim() || ""
      }]);
      setCurrentlyDrawing(true);

      const inputField = document.createElement('DIV');
      inputField.type = "text";
      inputField.contentEditable = true;
      inputField.className = "annotation-text-input";
      inputField.innerHTML = measurement?.text?.trim() || "";

      const inputFieldUpdateStyle = (left, top) => {
        const fontSize = ANNOTATION_FONT_SIZE / mousePosition.canvasPixelRatio;
        inputField.style = `--left: ${left}px; --top: ${top - fontSize}px; --font-size: ${fontSize}px; color: ${ANNOTATION_COLOR}`;
      }

      const inputTextOnBeforeSaving = (text) => text.replace(/\n|\r/g, "");

      if (measurement) {
        deleteMeasurement({...measurement, id: measurementId});
        inputFieldUpdateStyle(measurement?.from.x / mousePosition.canvasPixelRatio, measurement?.from.y / mousePosition.canvasPixelRatio);
      } else {
        inputFieldUpdateStyle(mousePosition.canvasWrapperX, mousePosition.canvasWrapperY);
      }

      const mouseUpEventListener = {
        handleEvent: (e) => {
          e?.preventDefault();
          e?.stopPropagation();
          window.removeEventListener('mouseup', mouseUpEventListener);
          canvasRef?.current?.parentNode.querySelector('.image-manipulation-annotation-editor').appendChild(inputField);
          inputField.focus();
          inputField.addEventListener("keydown", keyDownEventListener, false);
          inputField.addEventListener("keyup", keyUpEventListener, false);
          inputField.addEventListener("blur", endAnnotatingEventListener);
          if (measurement) editorRef?.current?.addEventListener("click", blurInputField);
        }
      }

      const keyDownEventListener = {
        handleEvent: (e) => {
          // Prevent editor shortcuts from being triggered
          e.stopPropagation();
        }
      }

      const keyUpEventListener = {
        handleEvent: (e) => {
          e.stopPropagation();
          if (["Enter", "Esc", "Tab"].includes(e.code)) {
            e.preventDefault();
            inputField.blur();
            return;
          }
          inputField.scrollTo({ left: 0 });

          setCurrentDrawing(currentDrawing => [{
            type: "text",
            from: currentDrawing.from,
            text: inputTextOnBeforeSaving(inputField.innerText)
          }]);
        }
      }

      const blurInputField = () => {
        inputField.blur();
      }

      const endAnnotatingEventListener = {
        handleEvent: (e) => {
          if (inputField.innerText) {
            addNewMeasurement({
              type: "text",
              from: measurement?.from ?? initialPosition,
              text: inputTextOnBeforeSaving(inputField.innerText)
            });
          }

          editorRef.current?.removeEventListener("click", blurInputField);

          setCurrentDrawing(null);
          setCurrentlyDrawing(false);
          setMode("edit");

          inputField.parentNode.removeChild(inputField);
          resolve();
        }
      }


      if (measurement) {
        mouseUpEventListener.handleEvent();
      } else {
        window.addEventListener('mouseup', mouseUpEventListener);
      }
    }
  })
}

/* The user has clicked on the canvas with the drawing tool
 * Here we start the workflow to draw a shape on the canvas */
const onStartDrawing = (e, componentContext) => {
  return new Promise((resolve, reject) => {
    const { mode } = componentContext;

    switch (mode) {
      case "annotate":
        return onStartAnnotate(componentContext)
          .then((res) => resolve(res))
          .catch((res) => reject(res));
      case "calibrate":
        return onStartDrawingCalibration(componentContext)
          .then((res) => resolve(res))
          .catch((res) => reject(res));
      case "drawing-line":
        return onStartDrawingLine(componentContext)
          .then((res) => resolve(res))
          .catch((res) => reject(res));
      case "drawing-ellipse":
        return onStartDrawingEllipse(componentContext)
          .then((res) => resolve(res))
          .catch((res) => reject(res));
      case "drawing-patch":
        return onStartDrawingPatch(componentContext)
          .then((res) => resolve(res))
          .catch((res) => reject(res));
      default:
        return;
    }
  });
}


const ImageManipulation = ({ t: __, instanceId }) => {
  const examinationContext = useContext(ExaminationContext);
  const windowContext = useContext(WindowContext);
  const { isFeatureFlagEnabled } = useAuth();
  const history = useHistory();
  const examId = Number(useParams()?.examId);
  const dicomInstanceId = instanceId || useParams()?.dicomInstanceId;
  const instance = ((examinationContext?.instances) || []).find(({ id }) => id == dicomInstanceId);

  const trackEvent = useCallback((event_type, event_data) => {
    ResourceApi.createBIEvent({
      ...event_data,
      event_type: event_type,
      examination_id: examId,
      instance_id: dicomInstanceId
    });
  }, [ResourceApi.createBIEvent]);

  /* Image of the dicom instance we will use to draw the image on the canvas */
  const img = useMemo(() => new Image(), []);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);

  /* Ratio between the loaded image and the dicom image */
  const dicomColumns = parseInt(instance?.metadata?.Columns);
  const dicomRows = parseInt(instance?.metadata?.Rows);

  const previewCanvasRatio = {
    x: img?.width / dicomColumns,
    y: img?.height / dicomRows,
  }

  /* Mode of the editor. It can be:
   * annotate: The user can write annotations on the canvas
   * drawing-*: The user can paint on the canvas using the default tool to draw ellipses or lines or patches
   * edit: The user can grab a shape and edit it
   * move: The user can move around the canvas image. And zoom-in and out -> TODO Not implemented yet
   */
  const [mode, setMode] = useState("edit");
  const [currentlyDrawing, setCurrentlyDrawing] = useState(false);
  const [currentDrawing, setCurrentDrawing] = useState(null);
  const [savedMeasurements, setSavedMeasurements] = useState(null);
  const [measurements, setMeasurements] = useState({});
  const [caption, setCaption] = useState({position: "auto"}); // "auto" will hook the caption to the bottom-left corner
  const [selectionArea, setSelectionArea] = useState(null);

  const [operationStack, setOperationStack] = useState([]);
  const [unDoneOperationStack, setUnDoneOperationStack] = useState([]);
  const [onKeyDownOperationStack, setOnKeyDownOperationStack] = useState(null);

  const [selectedElements, setSelectedElements] = useState([]);

  const sizeOfCalibrationSegment = useMemo(() => Object.values(measurements).find(m => m.type === "calibration")?.size, [measurements]); // in cm
  const setSiteOfCalibrationSegment = (valueInCm) => {
    setMeasurements(measurements => {
      return Object.fromEntries(
        Object.entries(measurements || {}).map(m => {
          return m[1].type === "calibration"
            ? [m[0], { ...m[1], size: Number(valueInCm) }]
            : m;
        })
      );
    });
  }
  const customPixelSpacing = useMemo(() => {
    const measurement = Object.values(measurements).find(m => m.type === "calibration");
    if (!measurement) return null;

    const distance = Math.sqrt(
      squareDistance(
        {
          x: measurement.from.x,
          y: measurement.from.y,
        },
        {
          x: measurement.to.x,
          y: measurement.to.y,
        }
      )
    );
    const pixelSpacing = (Number(measurement.size) || 10) / distance / dicomColumns * img.width;
    return [pixelSpacing, pixelSpacing];
  }, [measurements]);

  const [mmPerPixelY, mmPerPixelX] = customPixelSpacing || instance?.metadata?.PixelSpacing?.split("\\")?.map(parseFloat) || [null, null];

  /* Convert canvas distance to milimeter distance */
  const canvasToLocalmm = {
    x: (canvasLength) => canvasLength * dicomColumns / img.width * mmPerPixelX,
    y: (canvasLength) => canvasLength * dicomRows / img.height * mmPerPixelY
  }

  const canvasRef = useRef(null);
  const editorRef = useRef(null);


  /* performOperation and unperformOperation are 2 functions to be used to do or undo any action on the canvas.
   * These function help us keep a track of what has been performed or undo.
   * This allow to provide the undo and redo action mechanism
   */

  const performOperation = useCallback((operation) => {
    switch (operation.type) {
      case "add-measurement":
        setMeasurements(measurements => {
          return ({ ...measurements, [operation.id]: operation.measurement })
        });
        return
      case "edit-measurement":
        setMeasurements(measurements => ({ ...measurements, [operation.id]: operation.new }));
        return
      case "delete-measurement":
        setMeasurements(measurements => {
          delete measurements[operation.id]
          return { ...measurements };
        });
        return
      default:
        console.error("Unkown operation", operation.type, "\nnothing done");
    }
  }, [measurements])

  const unperformOperation = useCallback((operation) => {
    switch (operation.type) {
      case "add-measurement":
        delete measurements[operation.id]
        setMeasurements({ ...measurements })
        return
      case "edit-measurement":
        setMeasurements({ ...measurements, [operation.id]: operation.previous })
        return
      case "delete-measurement":
        setMeasurements({ ...measurements, [operation.id]: operation.measurement })
        return
      default:
        console.error("Unkown operation", operation.type, "\nnothing reverted")
    }
  }, [measurements])

  const operationBIAttributes = useCallback((operation) => {
    return {
      operation_type: operation.type || "",
      tool_type: operation.measurement?.type || operation.new?.type || ""
    }
  }, []);

  /* This function pop the last modification made to the canvas to implement a sort of CTRL+Z on it
   * This function is a callback because we need to modify the mousedown event on the window every time it is modified 
   */
  const undoLastOperation = useCallback(() => {
    if (operationStack.length < 1) return
    const lastOperation = operationStack[operationStack.length - 1]
    trackEvent("measurement-tool-undo", operationBIAttributes(lastOperation));
    const newOperationStack = operationStack.slice(0, -1)
    const newUnDoneOperationStack = [...unDoneOperationStack, lastOperation]
    setOperationStack(newOperationStack)
    setUnDoneOperationStack(newUnDoneOperationStack)
    unperformOperation(lastOperation)
  }, [operationStack, unDoneOperationStack, unperformOperation])

  const redoLastUnperformedOperation = useCallback(() => {
    if (unDoneOperationStack.length < 1) return
    const lastOperation = unDoneOperationStack[unDoneOperationStack.length - 1]
    trackEvent("measurement-tool-redo", operationBIAttributes(lastOperation));
    const newUnDoneOperationStack = unDoneOperationStack.slice(0, -1)
    const newOperationStack = [...operationStack, lastOperation]
    setOperationStack(newOperationStack)
    setUnDoneOperationStack(newUnDoneOperationStack)

    performOperation(lastOperation)
  }, [operationStack, unDoneOperationStack, performOperation])

  const addNewOperation = useCallback((operation) => {
    trackEvent("measurement-tool-measure", operationBIAttributes(operation));
    setUnDoneOperationStack([])
    setOperationStack([...operationStack, operation])
    performOperation(operation)
  }, [operationStack, performOperation])


  const addNewMeasurement = useCallback((measurement) => {
    const id = crypto.randomUUID();
    if (measurements[id]) // Should never have a conflict but in case
      return addNewMeasurement(measurement);
    addNewOperation({ type: "add-measurement", id, measurement });
    return id;
  }, [measurements, crypto.randomUUID, addNewOperation]);
  
  const deleteMeasurement = useCallback((measurement) => {
    addNewOperation({
      type: "delete-measurement",
      id: measurement.id,
      measurement: measurement,
    });
  }, [addNewOperation]);

  const deleteSelectedMeasurements = useCallback(() => {
    for (const elm of selectedElements) {
      deleteMeasurement(elm);
    }
    setSelectedElements([]);
  }, [selectedElements, deleteMeasurement, setSelectedElements]);


  /* vars passed to all the non-react functions */
  const componentContext = {
    mode,
    canvasRef,
    editorRef,
    img,
    setMode,
    selectionArea,
    setSelectionArea,
    selectedElements,
    setSelectedElements,
    currentlyDrawing,
    setCurrentlyDrawing,
    currentDrawing,
    setCurrentDrawing,
    measurements,
    addNewMeasurement,
    deleteMeasurement,
    deleteSelectedMeasurements,
    setMeasurements,
    caption,
    setCaption,
    addNewOperation,
    canvasToLocalmm,
  };

  
  /* If the undoLastOperation is modified (because modification stack or measurements is modified) we update the window event handler with the new function */
  useEffect(() => {
    if (onKeyDownOperationStack) {
      window.removeEventListener("keydown", onKeyDownOperationStack, false)
    }
    const newOnKeyDownOperationStack = {
      handleEvent: (e) => {
        if ((e.metaKey || e.ctrlKey) && e.key == "z") {
          e.preventDefault();
          e.stopPropagation();
          return undoLastOperation()
        }
        if ((e.metaKey || e.ctrlKey) && e.key == "y") {
          e.preventDefault();
          e.stopPropagation();
          return redoLastUnperformedOperation()
        }
        return
      }
    }
    window.addEventListener("keydown", newOnKeyDownOperationStack, false)
    setOnKeyDownOperationStack(newOnKeyDownOperationStack)
  }, [undoLastOperation])

  /* Load examination */
  useEffect(() => {
    examinationContext.loadExamination(examId)
  }, [examId])

  const loadMeasurements = useCallback(({ user_edits: { measurement, caption } } = { user_edits: { measurement: false, caption: false }}) => {
    if (!measurement) {
      setMeasurements({});
      setSavedMeasurements("");
    } else {
      const formattedMeasurements = measurement.reduce((acc, { id, type, content }) => {
        acc[id] = { type, ...content }
        return acc
      }, {});
      setMeasurements(formattedMeasurements);
      setSavedMeasurements(JSON.stringify(measurement));
    }

    if (caption) {
      setCaption(caption);
    }
  }, [setMeasurements, setSavedMeasurements, setCaption]);

  useEffect(() => {
    if (!!instance && JSON.stringify(instance?.user_edits?.measurement) != savedMeasurements) {
      /* Some one has edited the measurement on an other interface. Let's load it. */
      loadMeasurements(instance)
    }
  }, [instance])

  /* Load the dicom instance */
  useEffect(() => {
    if (!canvasRef) return
    const renderImage = () => renderCanvas(false);
    img.addEventListener('load', renderImage);
    
    if (instance?.meta_origin?.source == "secondary_capture" && instance?.meta_origin?.reason == "measurement") {
      img.src = `/api/v2/dicom-instance/${instance.meta_origin?.parent_dicom_instance_id}/preview?fallback=true`;
    } else {
      img.src = `/api/v2/dicom-instance/${dicomInstanceId}/preview?fallback=true`;
    }
    
    return () => {
      img.removeEventListener('load', renderImage);
    }
  }, [canvasRef, dicomInstanceId, measurements]);

  /* custom behaviors for specific tools */
  useEffect(() => {
    const currentPixelRatio = Object.entries(measurements)?.find(([id, m]) => m.type === "calibration");
    if (mode === "calibrate" && currentPixelRatio) {
      setSelectedElements([{ id: currentPixelRatio[0], type: currentPixelRatio[1].type }]);
    } else if (mode !== "edit") {
      setSelectedElements([]);
    } else if (mode === "edit" && selectedElements.some(elm => elm.type === "calibration")) {
      setSelectedElements([]);
    }
  }, [mode, measurements]);

  const onCanvasMouseDown = (e) => {
    switch (mode) {
      case "annotate":
        onAnnotateMouseDown(e, componentContext);
        return;
      case "drawing-line":
      case "drawing-ellipse":
      case "drawing-patch":
        onDrawingMouseDown(e, componentContext);
        return;
      case "calibrate":
        Object.values(measurements).some(m => m.type === "calibration")
          ? onEditMouseDown(e, componentContext)
          : onDrawingMouseDown(e, componentContext);
        return;
      case "edit":
        onEditMouseDown(e, componentContext);
        return;
      case "grab":
        /* TODO this mode will be used to move / zoom on the image */
        return;
    }
  }

  const getCanvasPixelRatio = useCallback(() => {
    const canvas = canvasRef.current?.getBoundingClientRect();
    if (!canvas) return 0;
    return canvasRef.current?.width / canvas.width;
  }, [canvasRef]);

  const refreshCanvasPixelRatio = () => {
    mousePosition.canvasPixelRatio = getCanvasPixelRatio();
  }

  const onCanvasMouseMove = useCallback((e) => {
    // we are assuming that canvas and canvas' wrapper have same size and position
    const canvas = canvasRef.current?.getBoundingClientRect();
    const canvasPixelRatio = getCanvasPixelRatio();

    mousePosition = {
      mouseX: e.clientX,
      mouseY: e.clientY,
      wrapperOffsetX: canvas.x,
      wrapperOffsetY: canvas.y,
      canvasWrapperX: e.clientX - canvas.x,
      canvasWrapperY: e.clientY - canvas.y,
      canvasX: (e.clientX - canvas.x) * canvasPixelRatio,
      canvasY: (e.clientY - canvas.y) * canvasPixelRatio,
      canvasPixelRatio,
    };
  }, [canvasRef, getCanvasPixelRatio]);

  const onCanvasMouseWheel = useCallback((e) => {
    let deltaY = mouseWheel.deltaY || 0;
    let deltaX = mouseWheel.deltaX || 0;
    if ((deltaY >= 0 && e.deltaY < 0) || (deltaY <= 0 && e.deltaY > 0)) deltaY = 0;
    if ((deltaX >= 0 && e.deltaX < 0) || (deltaX <= 0 && e.deltaX > 0)) deltaX = 0;

    mouseWheel = {
      deltaY: deltaY + (e.deltaY > 0 ? 1 : -1),
      deltaX: deltaX + (e.deltaX > 0 ? 1 : -1),
    }
  }, [mouseWheel]);

  useEffect(() => {
    refreshCanvasPixelRatio();
  }, [canvasRef.current]);

  useEffect(() => {
    window.addEventListener("resize", refreshCanvasPixelRatio);
    return () => window.removeEventListener("resize", getCanvasPixelRatio);
  }, []);

  useEffect(() => {
    setImgLoaded(false);
  }, [img]);
  
  const getSortedMeasurements = useCallback(() => Object.entries(measurements).map(([id, measurement], index) => ({
    ...measurement,
    id,
    order: measurement.type === "patch" ? -1 : index,
  })).sort((a, b) => a.order - b.order),
  [measurements]);

  const renderSVGEditor = useCallback(() => {
    if (!img.width) return;
    if (!img.height) return;
    if (!imgLoaded) setImgLoaded(true);
    const objects = [];
    const sortedMeasurements = getSortedMeasurements();

    if (currentDrawing && !currentDrawing.some(c => c.type === "text")) {
      objects.push(
        <g key="currentDrawing" data-id="">{
          currentDrawing.map((shape, index) => drawSVGShape(shape, componentContext, `currentDrawing_${index}`))
        }</g>
      );
    }

    objects.push( sortedMeasurements.map(measurement => {
      if (mode !== "calibrate" && measurement.type === "calibration") return;
      if (mode === "calibrate" && measurement.type !== "calibration") return;

      return <g key={measurement.id} className={`measurement-${measurement.type} ${componentContext.selectedElements.find(elm => elm.id === measurement.id) ? 'selected' : ''}`} data-id={measurement.id}>{
        measurementToShape(measurement, "svg").map((shape, index) => drawSVGShape(shape, componentContext, `${measurement.id}_${index}`))
      }</g>;
    }));

    const measurementsForCaption = sortedMeasurements.filter(m => ["line", "ellipse"].includes(m.type));
    if (measurementsForCaption.length) {
      objects.push(
        <g key="caption" className="caption" data-id="caption">{drawSVGCaption(measurementsForCaption, componentContext)}</g>
      );
    }

    return objects;
  }, [mode, componentContext, img, imgLoaded, currentDrawing, getSortedMeasurements, measurementToShape, drawSVGShape]);

  const renderCanvas = useCallback((drawMeasurements = false) => {
    if (!canvasRef?.current) return;
    if (!img.width) return;
    if (!img.height) return;
    if (!imgLoaded) setImgLoaded(true);

    const canvas = canvasRef?.current;
    canvas.height = img.height;
    canvas.width = img.width;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height);
    
    // force refresh (and SVG re-rendering) in case of canvas changes size
    setCanvasWidth(canvas.width);
    setCanvasHeight(canvas.height);

    if (drawMeasurements) {
      const measurementsStyle = { strokeStyle: ANNOTATION_COLOR };
      getSortedMeasurements().forEach(measurement => {
        if (measurement.type === "calibration") return;
        return measurementToShape(measurement, "canvas").forEach((shape) => drawShape(shape, canvas, measurementsStyle, previewCanvasRatio));
      });
      drawCaption(canvas, componentContext, previewCanvasRatio);
    }
  }, [componentContext, canvasRef?.current, img, imgLoaded, getSortedMeasurements, previewCanvasRatio, measurementToShape, drawShape]);

  const selectionAreaStyle = useCallback(() => {
    const fromX = selectionArea?.from?.x || 0;
    const fromY = selectionArea?.from?.y || 0;
    const toX = selectionArea?.to?.x ?? fromX;
    const toY = selectionArea?.to?.y ?? fromY;

    return {
      top: Math.min(fromY, toY),
      height: Math.abs(fromY - toY),
      left: Math.min(fromX, toX),
      width: Math.abs(fromX - toX),
    }
  }, [selectionArea]);

  const closeImageEditor = useCallback(() => {
    if (isFeatureFlagEnabled("sonio.multiscreen") && windowContext.isDetached) {
      windowContext.postMessageToView("core", { event: "refreshInstances" });
      windowContext.postMessageToView("core", { event: "refreshInstanceImg", mediaId: dicomInstanceId });
      // In multiscreen mode, we simply go back to the image from which the measurement started. 
      history.goBack();
    } else {
      history.push(`/exam/${examId}/#media-${dicomInstanceId}`);
    }
  }, [dicomInstanceId, isFeatureFlagEnabled, windowContext.isDetached, windowContext.postMessageToView, history]);

  const onSave = useCallback(() => {
    if (!img || !instance) return;
    trackEvent("measurement-tool-save", {});
    setSaving(true);
    renderCanvas(true);

    const formattedMeasurements = Object.entries(measurements).map(([id, measurement]) => {
      const { type, ...content } = measurement
      return {
        id,
        type,
        content
      }
    });

    ResourceApi.updateDicomInstance(dicomInstanceId, {
      user_edits: {
        measurement: formattedMeasurements,
        caption
      },
      thumbnailDataURL: getThumbnailDataURL(canvasRef.current)
    }).then(({ data: { data } }) => {
      loadMeasurements(data);
      uncacheThumbnail(dicomInstanceId);
    }).finally(() => {
      setSaving(false);
      closeImageEditor();
    });
  }, [img, instance, trackEvent, renderCanvas, measurements, ResourceApi.updateDicomInstance, getThumbnailDataURL, loadMeasurements, uncacheThumbnail, closeImageEditor]);

  const [ctrlIsPressed, setCtrlIsPressed] = useState(false);

  /** keyboard shortcuts */
  const onKeyDown = useCallback((e) => {
    switch (e.code) {
      case "Control":
        setCtrlIsPressed(true);
        break;
      case "KeyZ":
        if (ctrlIsPressed) undoLastOperation();
        break;
      case "KeyY":
        if (ctrlIsPressed) redoLastUnperformedOperation();
        break;
      case "KeyL":
        setMode("drawing-line");
        break;
      case "KeyC":
        setMode("drawing-ellipse");
        break;
      case "KeyP":
        setMode("drawing-patch");
        break;
      case "KeyA":
        setMode("annotate");
        break;
      case "KeyR":
        setMode("calibrate");
        break;
      case "KeyS":
        setMode("edit");
        break;
      case "Delete":
      case "Backspace":
        if (["edit", "calibrate"].includes(mode)) deleteSelectedMeasurements();
        break;
    }

  }, [mode, selectedElements, ctrlIsPressed, setMode, setCtrlIsPressed]);

  const onKeyUp = useCallback((e) => {
    switch (e.code) {
      case "Control":
        setCtrlIsPressed(false);
        break;
    }
  }, [setCtrlIsPressed]);

  useEffect(() => {
    window.addEventListener("mousemove", onCanvasMouseMove);
    window.addEventListener("wheel", onCanvasMouseWheel);
    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);
    return () => {
      window.removeEventListener("mousemove", onCanvasMouseMove);
      window.removeEventListener("wheel", onCanvasMouseWheel);
      window.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("keyup", onKeyUp);
    }
  }, [instanceId, onKeyDown, onKeyUp, onCanvasMouseMove, onCanvasMouseWheel]);
  /** /keyboard shortcuts */

  return (
    <div className="image-manipulation-container" data-window-detached={windowContext.isDetached}>
      <div className="image-manipulation-tools-section">
        <div className="image-manipulation-tools-grid">
          <Button icon="pointer" variant={mode === "edit" ? "" : "outline"} onClick={() => setMode("edit")} hint="Select (S)" />
          <hr />
          <Button icon="annotation" variant={mode === "annotate" ? "" : "outline"} onClick={() => setMode("annotate")} hint="Annotate (A)" />
          <Button icon="line" variant={mode === "drawing-line" ? "" : "outline"} onClick={() => setMode("drawing-line")} hint="Draw line (L)" />
          <Button icon="draw-circle" variant={mode === "drawing-ellipse" ? "" : "outline"} onClick={() => setMode("drawing-ellipse")} hint="Draw Circle (C)" />
          <Button icon="patch" variant={mode === "drawing-patch" ? "" : "outline"} onClick={() => setMode("drawing-patch")} hint="Patch (P)" />
          <hr />
          <div className="image-manipulation-tool">
            <Button icon="ruler" variant={mode === "calibrate" ? "" : "outline"} onClick={() => setMode("calibrate")} hint="Calibrate (R)" />
            {customPixelSpacing && (
              <div className="image-manipulation-tool_edited-button" />
            )}
            {mode === "calibrate" && (
              <ArrangeOnTop variant="balloon">
                <div className="image-manipulation-tools-grid_calibration-balloon">
                  {selectedElements.some(elm => elm.type === "calibration")
                    ? <NumericInput label={__("imageManipulation.calibration.segmentSize")} value={sizeOfCalibrationSegment} onChange={setSiteOfCalibrationSegment} suffix=" mm" />
                    : <div className="hint">{__("imageManipulation.calibration.initialInstructions")}</div>
                  }
                </div>
              </ArrangeOnTop>
            )}
          </div>
        </div>
      </div>
      <div className="image-manipulation-canvas-container" data-mode={mode}>
        <div className="image-manipulation-canvas-wrapper" style={{ aspectRatio: `${img.width} / ${img.height}` }}>
          <div className="image-manipulation-canvas-innerWrapper" style={{ aspectRatio: `${img.width} / ${img.height}` }}>
            <canvas
              className="image-manipulation-canvas"
              ref={canvasRef}
              onMouseDown={onCanvasMouseDown}
              />

            <div className="image-manipulation-annotation-editor" />
            
            <div className="image-manipulation-measurements-editor" ref={editorRef}>
              <svg
                viewBox={`0 0 ${img.width} ${img.height}`}
                onMouseDown={onCanvasMouseDown}
              >
                {renderSVGEditor()}
              </svg>
            </div>

            {selectionArea ? (
              <div className="image-manipulation-selection-area" style={selectionAreaStyle()} />
            ) : null}
          </div>
        </div>

      </div>

      <div className="image-manipulation-warnings-wrapper">
        {img && instance && previewCanvasRatio.x != previewCanvasRatio.y && (
          <WarningMessage
            short={__("imageManipulation.imageDicomRatioNotSquare.short")}
            full={__("imageManipulation.imageDicomRatioNotSquare.full")}
          />
        )}
        {instance && mmPerPixelY != mmPerPixelX && (
          <WarningMessage
            short={__("imageManipulation.dicomPixelSpacingNotSquare.short")}
            full={__("imageManipulation.dicomPixelSpacingNotSquare.full")}
          />
        )}
        {instance && !mmPerPixelY && !mmPerPixelX && mode !== "calibrate" && (
          <WarningMessage
            short={__("imageManipulation.notCalibrated.short")}
            full={__("imageManipulation.notCalibrated.full")}
            cta={<>
              <Button label={__("imageManipulation.notCalibrated.close")} variant="outline" color="common" onClick={closeImageEditor} />
              <Button label={__("imageManipulation.notCalibrated.calibrate")} color="common" onClick={() => setMode("calibrate")} />
            </>}
          />
        )}
        {instance && customPixelSpacing && (
          <div className="image-manipulation-warnings_note">
            {__("imageManipulation.manuallyCalibrated.warning")}
          </div>
        )}
      </div>

      <div className="image-manipulation-status-bar">
        <div className="image-manipulation-status-bar_section">
          <Button icon="undo" variant="outline" disabled={!!operationStack.length ? false : true} onClick={undoLastOperation} hint="Undo (Ctrl-Z)" />
          <Button icon="redo" variant="outline" disabled={!!unDoneOperationStack.length ? false : true} onClick={redoLastUnperformedOperation} hint="Redo (Ctrl-Y)" />
        </div>
        <div className="image-manipulation-status-bar_section">
          {!!selectedElements.length && <>
            {__("imageManipulation.selectedElement" + (selectedElements.length === 1 ? "" : "s"), {count: selectedElements.length})}
            {__(":")}
            <Button icon="trash" onClick={deleteSelectedMeasurements} hint="Delete (Del)" />
          </>}
        </div>
        <div className="image-manipulation-status-bar_section">
          <Button label={__("imageManipulation.cancel")} onClick={closeImageEditor} disabled={saving} variant="outline" />
          <Button label={__("imageManipulation.save")} onClick={onSave} disabled={saving} />
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(ImageManipulation);
