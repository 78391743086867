/**
 * Arrange the content on top of any other content, out of any scrolling or overflow-hidden container, keeping it linked to it's parent.
 * Particularly useful for dropdown options and search results.
 */

import { useEffect, useRef, useState } from "react";
import { renderToStaticMarkup } from 'react-dom/server';
import "./ArrangeOnTop.css";

const ArrangeOnTop = ({ children, variant = "dropdown" }) => {
  const wrapperClassName = `arrange-on-top_wrapper`;
  const [spacing, setSpacing] = useState({ top: 0, bottom: 0, left: 0, right: 0, balloonArrowLeft: 0, position: 'bottom', loading: true });
  const [scrollableParents, setScrollableParents] = useState([]);
  const dropdown = useRef(null);
  const placeholder = useRef(null);

  const container = placeholder?.current;

  const mountEvents = () => {
    window.addEventListener('resize', refreshDropdownPosition, true);
    for (const node of scrollableParents) {
      node.addEventListener('scroll', refreshDropdownPosition);
    }
  };

  const unmountEvents = () => {
    window.removeEventListener('resize', refreshDropdownPosition, true);
    for (const node of scrollableParents) {
      node.removeEventListener('scroll', refreshDropdownPosition);
    }
  };

  useEffect(() => {
    dropdown.current && document.querySelector('.App > div').appendChild(dropdown.current);
    unmountEvents();
    mountEvents();
    refreshDropdownPosition();

    const container = placeholder.current;

    const scrollableParents = [];
    for (var node = container; node; node = node.parentNode) {
      if (node.scrollHeight > node.clientHeight && !scrollableParents.includes(node)) scrollableParents.push(node);
    }

    setScrollableParents(scrollableParents);
    return () => {
      dropdown.current && container && container.contains(dropdown.current) && container.removeChild(dropdown.current);
      unmountEvents();
      document.querySelectorAll(`.${wrapperClassName}`).forEach(elm => elm?.parentNode.removeChild(elm));
    };
  }, []);

  useEffect(() => {
    refreshDropdownPosition();
  }, [renderToStaticMarkup(children), container]);

  const refreshDropdownPosition = () => {
    if (!container) return false;
    
    const position = container?.getBoundingClientRect();
    if (!position) return false;

    const spacing = {
      display: 'block',
      top: parseInt(position.bottom),
      left: parseInt(position.left),
      position: 'top',
      minWidth: container?.offsetWidth,
      loading: false,
    };

    if (!dropdown.current) return;

    dropdown.current.style.height = 'auto';
    dropdown.current.style.maxHeight = 'none';

    const maxScrollHeight = Math.ceil(Math.min(Math.max(window.innerHeight - position.bottom - 30, position.top - 30), dropdown.current.scrollHeight, dropdown.current.clientHeight));
    spacing.position = window.innerHeight - position.bottom < maxScrollHeight ? 'top' : 'bottom';
    spacing.maxHeight = maxScrollHeight;
    spacing.childrenHeight = dropdown.current.clientHeight;
    if (spacing.position === 'top') {
      spacing.top = parseInt(position.top - maxScrollHeight);
    }
    if (window.innerWidth < spacing.left + dropdown.current.offsetWidth) {
      spacing.left = position.right - dropdown.current.offsetWidth;
    }

    dropdown.current.style.height = spacing.maxHeight + 'px';
    dropdown.current.style.maxHeight = spacing.maxHeight + 'px';

    const placeholderPosition = placeholder.current?.getBoundingClientRect();
    if (placeholderPosition) {
      spacing.balloonArrowLeft = spacing.left - placeholderPosition.left + (placeholderPosition.width / 2);
    }

    setSpacing(spacing);
  };

  return <span className="arrange-on-top_placeholder" ref={placeholder}>
    <div className={`${wrapperClassName} variant-${variant} position-${spacing.position} ${spacing.loading ? 'loading' : ''}`} ref={dropdown} style={{
      display: spacing.display,
      top: spacing.top,
      left: spacing.left,
      minWidth: spacing.minWidth,
      maxWidth: spacing.maxWidth,
      maxHeight: spacing.maxHeight,
      "--balloon-arrow-left": spacing.balloonArrowLeft + 'px',
    }}>
      <div className="arrange-on-top_inner">
        {children}
      </div>
    </div>
  </span>
}

export default ArrangeOnTop;
