import { QRCodeSVG } from "qrcode.react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "../../atoms/Button/Button";
import Icon from "../../atoms/Icon/Icon";
import TextInput from "../../atoms/TextInput/TextInput";
import { AppContext } from "../../context-providers/App";
import { ExaminationContext } from "../../context-providers/Examination";
import { ExaminationSharingContext } from "../../context-providers/ExaminationSharing";
import { useExamSharingState } from "../../providers/examSharing/index";
import ResourceApi from "../../services/resource";
import "./SharingPreviewPopup.css";
import PatientNameValidation, {
  isPatientNameValid,
} from "./PatientNameValidation";
import ExamPagedPrint, {
  pagedPrintStylesText,
  updatePagedPrintHeaderOffset,
} from "../../components/ExamPagedPrint/ExamPagedPrint";

const SharingPreviewPopup = ({
  t: __,
  onClose = () => {},
  annotate = undefined,
  documents = undefined,
}) => {
  const examinationContext = useContext(ExaminationContext);
  const { documentsToggle } = useContext(ExaminationSharingContext);
  const { site } = useContext(AppContext);
  const {
    totalActiveUploadedDocuments,
    selectedSharingImages,
    selectedSharingVideos,
  } = useExamSharingState();

  const history = useHistory();

  const printableFrame = useRef(null);

  const [sharingLabel, setSharingLabel] = useState("");
  const [sharingCode, setSharingCode] = useState("");
  const [shareTo, setShareTo] = useState("");
  const [mountNode, setMountNode] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [headerPlaceholderCount, setHeaderPlaceholderCount] = useState(0);

  const [patientNameConfirmed, setPatientNameConfirmed] = useState(false);
  const totalUploadedDocumentsCount = () => {
    if (documentsToggle) {
      return totalActiveUploadedDocuments;
    } else {
      return 0;
    }
  };
  const totalSharedMedia =
    totalUploadedDocumentsCount() +
    selectedSharingImages.length +
    selectedSharingVideos.length;

  useEffect(() => {
    if (examinationContext?.patient?.email_address) {
      setShareTo(examinationContext.patient.email_address);
    }
  }, [examinationContext?.patient?.email_address]);

  useEffect(() => {
    /** awaiting for DOM creation inside the iframe (Firefox only) */
    if (printableFrame.current)
      printableFrame.current?.addEventListener("load", () =>
        setIframeLoaded(true)
      );
  }, []);

  useEffect(() => {
    if (!iframeLoaded && !printableFrame.current?.contentWindow) return false;
    if (
      printableFrame.current?.contentWindow?.document?.head.getElementsByClassName(
        "main-style"
      ).length > 0
    )
      return false;

    const css = document.createElement("style");
    css.className = "main-style";
    css.appendChild(document.createTextNode(pagedPrintStylesText));
    printableFrame.current?.contentWindow?.document?.head.appendChild(css);

    setMountNode(printableFrame.current?.contentWindow?.document?.body);
  }, [iframeLoaded, printableFrame.current?.contentWindow]);

  const onShareInstances = async () => {
    let shareEmails = [];
    if (shareTo) shareEmails = [shareTo];
    setSharingCode("");
    setSharingLabel(__("examinationReview.waitingForSharingCode"));
    await ResourceApi.shareExamination(
      window.location.origin,
      examinationContext.examination.id,
      shareEmails,
      annotate,
      documents
    )
      .then((resp) => {
        const newSharingCode = resp.data.share_code;
        setSharingLabel(__("examinationReview.sharingCode"));
        setSharingCode(newSharingCode);
      })
      .catch((err) => {
        setSharingCode("");
        setSharingLabel(err.response.data.message);
      });
  };

  const handleClickMediaCounter = () => {
    history.push(
      `/exam/${examinationContext.examination.id}/report/sharing-list`
    );
    onClose();
  };

  const frameComponent = useMemo(() => {
    return (
      <ExamPagedPrint
        printOnlyQr={true}
        printingPreferenceImgPerPage={8}
        headerPlaceholderCount={headerPlaceholderCount}
        instancesToPrint={[]}
      />
    );
  }, [site, examinationContext?.share?.share_link]);

  const printIframe = (ref) => {
    const iframeWindow = ref.current.contentWindow || ref.current;
    updatePagedPrintHeaderOffset(
      iframeWindow.document,
      8,
      setHeaderPlaceholderCount
    );
    iframeWindow.focus();
    iframeWindow.print();
    return false;
  };

  return (
    <div className="examination-review-sharing-preview-popup-container">
      <div
        className={"examination-review-sharing-preview-popup email-option-open"}
      >
        <div className="share-popup-close" onClick={onClose}>
          <Icon name="close" />
        </div>
        <div className={"popup-qr-code-ui"}>
          {isPatientNameValid(examinationContext?.patient) ||
          patientNameConfirmed ? (
            <>
              <div className="sharing-popup-title">
                {__("examinationReview.popupTitle.prefix")}
                &nbsp;
                <span
                  className="sharing-popup-title-number"
                  onClick={handleClickMediaCounter}
                >
                  {__(`examinationReview.popupTitle.number`, {
                    count: totalSharedMedia,
                  })}
                </span>
                &nbsp;
                {__(`examinationReview.popupTitle.suffix`)}
              </div>
              <div className="sharing-popup-media-files">
                <div className="sharing-popup-media-files-item">
                  <Icon name="picture" />
                  <span className="count">
                    {__("examinationReview.imageCount.number", {
                      count: selectedSharingImages.length,
                    })}
                  </span>
                </div>
                <div className="vertical-line" />
                <div className="sharing-popup-media-files-item">
                  <Icon name="cinema" />
                  <span className="count">
                    {__("examinationReview.videoCount.number", {
                      count: selectedSharingVideos.length,
                    })}
                  </span>
                </div>
                <div className="vertical-line" />
                <div className="sharing-popup-media-files-item">
                  <Icon name="file-with-line" />
                  <span className="count">
                    {__("examinationReview.documentCount.number", {
                      count: totalUploadedDocumentsCount(),
                    })}
                  </span>
                </div>
              </div>
              <div className="popup-qr-code-outer-container">
                <div className="popup-qr-code-container">
                  <div className="sharing-options-label">
                    {__("examinationReview.qrCode.label")}
                  </div>
                  {examinationContext.share?.instant_share_link && (
                    <div
                      className="popup-qr-code"
                      data-test-instant-qr={`${examinationContext.share.instant_share_link}&source=qr`}
                    >
                      <QRCodeSVG
                        value={`${examinationContext.share.instant_share_link}&source=qr`}
                        size={"100%"}
                        level="Q"
                        data-test-id="qr-code-1"
                        includeMargin={false}
                        bgColor={"var(--color-black)"}
                        fgColor={"var(--color-white)"}
                      />
                    </div>
                  )}
                </div>
                <div className="sharing-options">
                  <div className="sharing-options-label">
                    {__("examinationReview.shareOption.labelPrint")}
                  </div>
                  <Button
                    label={__("examinationReview.qrCode.imprimer")}
                    variant="outline"
                    icon="print"
                    size="full-width"
                    onClick={() => printIframe(printableFrame)}
                  />
                  <hr className="sharing-popup-hr" />
                  <div className="sharing-options-label">
                    {__("examinationReview.shareOption.labelShare")}
                  </div>
                  <div className="flex-line-wrapper">
                    <TextInput
                      className="share-patient-email"
                      value={shareTo}
                      fullwidth
                      placeholder={__(
                        "examinationReview.shareOption.placeholder"
                      )}
                      onChange={setShareTo}
                    />
                    <Button
                      icon="arrow-right"
                      onClick={onShareInstances}
                      disabled={!shareTo || !totalSharedMedia}
                    />
                  </div>
                  {!!sharingLabel && (
                    <label className="success-sharing-label">
                      <Icon name="done" className="done-icon" />
                      <div>
                        {sharingLabel.split(".").map((text) => (
                          <span>{text}</span>
                        ))}{" "}
                        {!!sharingCode && <b>{sharingCode}.</b>}
                      </div>
                    </label>
                  )}
                </div>
              </div>
            </>
          ) : (
            <PatientNameValidation
              onClose={onClose}
              onConfirm={() => setPatientNameConfirmed(true)}
            />
          )}
        </div>
      </div>
      <iframe
        style={{ display: "none" }}
        ref={printableFrame}
        title="shared medias"
      >
        {mountNode && createPortal(frameComponent, mountNode)}
      </iframe>
      <div className="modal-background" onClick={onClose} />
    </div>
  );
};

export default withTranslation()(SharingPreviewPopup);
